.Card {
    display: block;
    text-decoration: none;
    background: white;
    border-radius: 4px;
    box-shadow: 0px 0px 13px 0px #0000001a;
    transition: all 0.2s;
    padding-bottom: 10px;
}
.Card:hover{
    box-shadow: 0px 0px 18px 0px #00000022;
}
.info {
    display: flex;
    align-items: center;
}
.dist_details{
    margin: 2px 12px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #dedede;
    padding-top: 7px;
}
.flex{
    display: inline-flex;
    margin-right: 5px;
    color: white;
    padding: 3px 6px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 3px;
    text-align: center;
    height: fit-content;
    min-width: 40px;
    justify-content: center;
    align-items: center;
}
.img_container {
    width: 65px;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.img_container + div{
    width: calc(100% - 60px);
    text-align: left;
}
.app_image {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    align-self: center;
    justify-self: center;
    border: 1px solid #e6e6e6;
}
.clientsList{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
}
.controls{
    display: flex;
    width: 160px;
    height: 30px;
    justify-content: space-between
}