.Card {
    display: block;
    text-decoration: none;
    background: white;
    border-radius: 4px;
    box-shadow: 0px 0px 13px 0px #0000001a;
    transform: scale(1);
    transition: all 0.2s;
    padding-bottom: 10px;
}

.Card:hover{
    transform: scale(1.03);
    box-shadow: 0px 0px 18px 0px #00000022;
}

.info {
    display: flex;
    align-items: center;
}
.info div span{
    color: #2f2f2f;
}
.dist_details{
    margin: 2px 12px 0px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    border-top: 1px solid #dedede;
    padding-top: 7px;
}
.dist_details span{
    display: inline-flex;
    background: gray;
    color: white;
    margin: 5px 0px 5px 5px;
    padding: 3px 6px;
    font-size: 11px;
    font-weight: 700;
    border-radius: 3px;
    text-align: center;
    height: fit-content;
    min-width: 40px;
    justify-content: center;
    align-items: center;
}
.dist_details svg{
    filter: brightness(10);
}

.img_container {
    width: 65px;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.img_container + div{
    width: calc(100% - 60px);
    text-align: left;
}

.app_image {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    align-self: center;
    justify-self: center;
    border: 1px solid #e6e6e6;
}