.Container{
	display: inline-flex;
	height: auto;
	align-items: center;
	width: auto;
	text-align: center;
	margin: 0px;
	transition: all 0.3s;
}
.Tooltip{
	display: block;
	position: fixed;
	min-width: 200px;
	background: #2f2f2f;
	color: white;
	border-radius: 4px;
	padding: 15px;
	opacity: 0;
	visibility: hidden;
	transition: all 0.2ss;
	z-index: 500;
	top: calc(100% + 10px);
	transform: translateX(-50%);
	left: 50%;
	font-weight: 300 !important;
}
.Tooltip span{
	color:white;
}
.Tooltip a{
	color: #81bbef;
	display: inline-block;
}
.Tooltip img{
	position: relative;
	width: 100%!important;
	height: 100%!important;
}
.Tooltip i{
	display: block;
	width: 0;
	height: 0;
	border: 10px solid #00000000;
	border-bottom: 10px solid black;
	position: absolute;
	left: calc(50% - 10px);
	top: -20px;
}
.Container:hover .Tooltip{
	opacity: 1;
	visibility: visible;
	transition: all 0s;
}