.fixed_header{
    width: 100%;
    table-layout: fixed;
    border-spacing: 0;
    border-radius: 5px;    
    margin-bottom: 10px;
}

.fixed_header th {
    color: #000000;
    border-bottom: 2px solid gray;
    padding: 7px 12px 7px 0px !important;
    width: 100%;
    font-size: 16px;
    height: 32px;
    font-weight: 500;
    box-sizing: border-box;
}

.fixed_header tr{
  display: flex;
}

.fixed_header tbody{
  display:block;
  width: 100%;
  overflow: auto;
  height: 180px;
  padding-top: 3px;
}

.fixed_header tbody::-webkit-scrollbar {
    width: 7px;
}
.fixed_header tbody::-webkit-scrollbar-track {
    background: transparent; 
}
.fixed_header tbody::-webkit-scrollbar-thumb {
    background: #88888866; 
    border-radius: 50px;
}

.fixed_header thead tr {
   display: flex;
}

.fixed_header td {
  padding: 5px 12px 5px 0px !important;
  width: 100%;
  border-bottom: 1px solid lightgray;
}
.fixed_header tr td, .fixed_header tr th{
  text-align: center;
}
.fixed_header tr td:first-child, .fixed_header tr th:first-child{
  text-align: left;
}
.fixed_header tr td:last-child, .fixed_header tr th:last-child{
  text-align: right;
}

@media (max-width: 1150px){
  .fixed_header th {
    height: unset;
  }
}