.linecard{
    margin: 20px;
}
.container{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
}  
.NoResults{
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 125px);
    width: 100%;
}
.NoResults b{
    color: #c9c9c9;
    font-weight: 400;
}
.Pills{
	text-align: left;
    padding: 0px 40px;
	margin-top: -5px;
}
.Pills > span{
	display: inline-block;
	width: fit-content;
	padding: 8px 16px;
	border-radius: 0px;
	border: 2px solid #8dbd40;
	color: #8dbd40;
	margin: 10px 5px;
	border-radius: 40px;
	cursor: pointer;
	font-weight: bold;
}
.PillSelected{
	background: #8dbe40 !important;
	color:white !important;
}
.buttonContainer{
    display: flex;
    justify-content: flex-end;
}
.preloadercontainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
}
.NoResults > span{
    display: grid;
    grid-template: repeat(100%, 2fr);
    grid-gap: 20px;
    align-items: center;
    text-align: center;
}
.BackArrow{
    position: absolute;
    left: 0px;
    display: block;
    top: 5px;
    width: fit-content;
    height: fit-content;
}
.BackArrow img{
	width: 20px;
	height: auto;
    display: block;
    cursor: pointer;
}

.field{
    margin-top: 15px;
}