.Card{
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    border: 1px solid gainsboro;
    border-radius: 5px;
    padding: 15px 15px 10px;
    text-align: left;
    background: white;
    min-height: 200px;
}
.Card h4{
    color: var(--aic-green);
    font-weight: 500;
    padding-bottom: 10px;
}
.Card p{
    margin: 0px 0px 20px;
}
.Card table{
    border-collapse: collapse;
    width: 100%;
}
.Card td{
    text-align: center;
    padding: 7px 0px;
}
.Card tr:not(:last-child){
    border-bottom: 1px solid gainsboro;
}
.Card td:first-child{
    text-align: left;
}
.Card td:last-child{
    text-align: right;
    font-weight: 500;
}
.description{
    margin-bottom: 10px !important;
}
.loader{
    background: white;
    position: absolute;
    width: 100%;
    height: calc(100% - 50px);
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 800;
}

@media (max-width: 650px){
    .FullWidthTable {
        width: calc(100vw - 15px);
        overflow-x: scroll;
    }

    .FullWidthTable table {
        width: 750px;
        overflow-x: scroll;
    }
}