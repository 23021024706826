.Container {
  display: flex;
  height: 100vh;
  widows: 100vw;
  padding-top: 50px;
}

.SideMenu {
  height: 100%;
  display: block;
  width: 190px;
}

.Content {
  width: calc(100% - 190px);
  height: 100%;
  overflow: auto;
  background: #f5f5f5;
}
.Content p {
  margin: 0;
}
@media (max-width: 800px) {
  .Content {
    width: 100%;
    padding: 0px 0px 40px;
  }
  .SideMenu {
    height: 0vh;
    width: 0vw;
  }
}
