.Row{
	display: flex;
	background: whitesmoke;
	margin: 10px 0px;
	padding: 10px;
	border-radius: 3px;
	cursor: pointer;
	transition: all 0.2s;
}
.Row:hover{
	background: #f0f0f0;
}
.Row > img{
	display: block;
	width: 50px;
	height: 50px;
	border-radius: 5px;
	margin-right: 10px;
	background: white;
}
.Row > div{
	display: grid;
}
.Row > div span{
	display: flex;
	align-items: center;
	padding: 0px 5px;
	font-weight: 700;
	text-align: left;
}
.Company{
	font-size: 12px;
	font-weight: 300 !important;
}
.Title{
	margin: 10px;
	text-align: center;
}
.NotMyApp{
	background: #c2c2c2;
	justify-content: center;
}
.Loader{
	padding: 40px 0px;
}
.Loader p{
	text-align: center;
	padding: 10px 0px;
}
