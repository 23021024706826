.Container{
    width: 100%;
    margin: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: white;
    justify-content: center;
    align-items: center;
}

.Content {
    height: fit-content;
    margin: 40px auto;
    width: fit-content;
}

.file{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    min-width: 300px;
    width: 100%;
    height: 80px;
    border: 4px dashed gainsboro;
}
.dragging{
    font-weight: bold;
}
.details{
    padding: 10px;
    border: 2px solid #7dc11a;
    border-radius: 5px;
    margin: 40px 0px 0px;
    display: flex;
    align-items: center;
}
.details img{
    margin: 10px;
}
.details tr td:first-child{
    font-weight: bold;
}
.details td{
    text-align: left;
}