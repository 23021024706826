body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: whitesmoke;
  font-family: roboto;
  font-weight: 300;
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
body * {
  font-family: "Roboto", sans-serif;
}
p,
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  display: none;
}
