.linecard{
    margin: 20px;
}
.container{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
}  
.NoResults{
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 125px);
    width: 100%;
}
.NoResults b{
    color: #c9c9c9;
    font-weight: 400;
}
.buttonContainer{
    display: flex;
    justify-content: flex-end;
}
.preloadercontainer {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
}
.NoResults > span{
    display: grid;
    grid-template: repeat(100%, 2fr);
    grid-gap: 20px;
    align-items: center;
    text-align: center;
}
.BackArrow{
    position: absolute;
    left: 0px;
    display: block;
    top: 5px;
    width: fit-content;
    height: fit-content;
}
.BackArrow img{
	width: 20px;
	height: auto;
    display: block;
    cursor: pointer;
}