.grid {
  position: relative;
  width: calc(100% - 20px);
  margin: auto;
  display: block;
  height: 100%;
}

.Header {
  padding: 20px;
}

.Header h3 {
  margin-bottom: 10px;
  text-align: left;
}

.AppsGrid {
  position: relative;
  width: 100%;
  padding: 25px;
  text-align: center;
  grid-template-columns: repeat(3, calc(33% - 13px));
  grid-gap: 25px;
  display: grid;
}

.Filter {
  background: white;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  box-shadow: 0px 0px 14px 0px #0000000d;
  border: 1px solid #f1f1f1;
}

.Filter svg {
  filter: brightness(2.5);
}

.Container {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.DropOptions {
  padding: 0px;
  width: fit-content;
  margin: 10px auto;
}

.SearchContainer {
  overflow: hidden;
  width: calc(100% - 60px);
}

.RadioContainer {
  display: flex;
  flex-flow: column;
  height: 50px;
  justify-content: space-between;
}

.Flex {
  display: flex;
  align-items: center;
}

.AL {
  text-align: left;
  display: block;
}
.NoResults {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 125px);
  width: 100%;
}
.NoResults span {
  color: #c9c9c9;
}
.Tabs {
  border-bottom: 1px solid whitesmoke;
  position: fixed;
  width: 100%;
  background: white;
  z-index: 200;
}

@media (max-width: 1300px) {
  .AppsGrid {
    grid-template-columns: repeat(3, calc(33% - 14px));
  }
}
@media (max-width: 1100px) {
  .AppsGrid {
    grid-template-columns: repeat(2, calc(50% - 12px));
  }
}
@media screen and (max-width: 500px) {
  .Tabs {
    width: 90% !important;
  }
  .Tabs > label {
    font-size: 0.775em;
  }
  .AppsGrid {
    grid-template-columns: repeat(1, calc(100% - 30px));
    justify-content: center;
  }
  .Filter {
    width: calc(100% - 120px);
  }
  .filtercontainer > span > b {
    font-size: small;
  }
}

@media (max-width: 770px) {
  .Filter {
    width: 35px;
    height: 35px;
  }
  .AppsGrid {
    padding: 5px;
    margin-bottom: 50px;
  }
}
@media (max-width: 650px) {
  .filtercontainer {
    grid-template-columns: 1fr;
  }
}
