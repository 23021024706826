.Container{
	width: 100%;
	height: 110px;
	border-radius: 8px;
	text-align: center;
    border: 1px dashed lightgray;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	overflow: hidden;
}
.Container div{
	/*position: relative;*/
}
.Container img{
	width: 30px;
	height: 30px;
	filter: unset;
	transition: all 0s;
}
.Container:not(.Disabled):hover .Icon, .Container.HasFiles .Icon{
	filter: sepia(1) hue-rotate(50deg) brightness(1.25) saturate(1.7);
}
.Container p{
	padding: 0px 5px;
	color: inherit;
	transition: all 0s;
	font-size: 14px;
	line-height: 19px;
}
.Container p b{
	margin: 5px 10px;
	padding: 1px 5px;
	border-radius: 3px;
	background: #5c7d2b;
	color: white;
	z-index: 900;
	cursor: pointer;
	display: inline-block;
}
.Container:not(.Disabled):hover p, .Container.HasFiles p{
	color: #98cc6b;
}
.Container input{
	position: absolute;
	opacity: 0;
	width: 100%;
	height: 100%;
	left: 0px;
	top: 0px;
	z-index: 100;
	cursor: pointer;
}
.Invalid img{
	filter: sepia(1) hue-rotate(310deg) brightness(1.25) saturate(4);
}
.Invalid p{
	color: #ff868a;
}
.Modified{
	background-color: #fddcc5 !important;
}
.Modified p{
	color:#000000 !important;
}
.Modified img{
	filter: brightness(0.8) !important;
}
.Modified b{
	background: #626262 !important;
}
.DisabledFilter{
	background: transparent;
	position: absolute !important;
	opacity: 0;
	width: 100%;
	height: 100%;
	left: 0px;
	top: 0px;
	z-index: 200;
}
.icons{
	display: flex;
	justify-content: center;
	align-items: center;
	visibility: hidden;
	opacity: 0;
	position: absolute;
	height: 100%;
	width: 100%;
	left: 0px;
	top: 0px;
	z-index: 700;
	transition: all 0.2s;
	background: #ffffffcc; 
}
.Container:hover .icons{
	visibility: visible;
	opacity: 1;
}
.icon{
	border: 1px solid #757575;
    border-radius: 50px;
    height: 24px;
    width: 25px;
    display: inline-flex;
    justify-content: center;
	align-items: center;
	margin: 0px 3px 0px;
}
.icon > *{
	fill: #757575;
}
.deleteIcon {
	width: 16px;
	height: 16px;
	position: absolute;
	right: 5px;
	top: 5px;
	background: #9e9e9e;
	opacity: 0;
	visibility: hidden;
	transition: all 0.2s;
}
.Container:not(.Disabled):hover .deleteIcon{
	opacity: 1;
	visibility: visible;
}
.Container p{
	margin: 0px;
}
.Container a{
	top: 1px;
    position: relative;
}