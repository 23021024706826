.Container{
    background: whitesmoke;
    width: 350px;
    margin-right: 21px;
    height: fit-content;
    transition: width 0.3s;
    border-radius: 5px;
    position: fixed;
    top: 7px;
    right: 90px;
}
.Focused{
	box-shadow: 0px 1px 20px 0px #0000003d;
}
.Container svg{
    filter: brightness(3);
    top: -6px;
    position: absolute;
}
.Container > input{
    position: relative;
    width: 100%;
    right: 0px;
    height: 34px;
    border: none;
    background: transparent;
    padding: 5px 5px 5px 40px;
    font-size: 16px;
    font-weight: 300;
    outline: none;
    color: black;
}
.Results{
    background: white;
}
.Results a{
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 10px;
	text-decoration: none;
}
.Results a:hover{
	background: whitesmoke;
}
.Results a img{
	width: 30px;
	height: 30px;
	border-radius: 50%;
}
.Results a img + span, .Results a div + span{
    padding-left: 10px;
}
.Title{
    width: 100%;
    color: #aaaaaa;
    font-size: 14px;
    padding: 5px 10px 5px;
    display: block;
    font-weight: 700;
    border-top: 1px solid #e0e0e0;
    text-align: left;
}
.Results a > span{
    display: block;
    width: 100%;
    text-align: left;
    color: black;
}
.Results a > span > span{
    display: block;
    font-weight: 300;
}
.Details{
    display: block !important;
    font-weight: bold !important;
    font-size: 11px !important;
    color: var(--aic-second-green) !important;
    padding-top: 3px !important;
}
.NoResults{
    padding: 10px;
    background: white;
    color: #8dbd40;
    font-weight: 500;
    font-size: 14px;
    border-top: 1px solid #e0e0e0;
}

@media (max-width: 770px){

    .Container{
        background: whitesmoke;
        width: calc(100% - 180px);
        margin-right: 21px;
        height: fit-content;
        border: 1px solid #eaeaea;
        transition: width 0.3s;
        border-radius: 3px;
        position: fixed;
        top: 12px;
        right: 100px;
        left: 70px;
        
    }

    .Results {
        width: 100vw;
        position: fixed;
        left: 0;
        top: 60px;
        box-shadow: 0px 5px 16px -5px #00000066;
    }
}