.BottomOptions{
	display: flex;
	justify-content: space-between;
	margin: 20px 0px 0px;
}
.BottomOptions *{
	color: gray;
	text-decoration: none;
	cursor: pointer;
	transition: all 0.2s;
}
.BottomOptions *:hover{
	color: #7ba932;
}
.Title{
	margin: 25px;
	color: #9E9E9E
}