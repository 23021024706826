.Form{
	background: white;
	width: 100%;
	max-width: 400px;
	text-align: center;
	display: block;
	border-radius: 3px;
	z-index: 40;
}

.Form > img{
	width: 100px;
	margin: 50px auto 20px;
	display: block;
}

.Form > div{
	padding: 0px 30px 25px;
}

.Container{
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
	width: 100%;
	z-index: 35;
}