.Dialog{
	position: fixed;
	z-index: 1000;
	width: 100vw !important;
	height: 100vh;
	left: 0;
	opacity: 0;
	visibility: hidden;
	top: 0;
	transition: all 0.3s;
	display: flex;
	align-items: center;
	justify-content: center !important;
	transition: all 0.2s;
}
.Dialog.Displayed{
	visibility: visible;
	opacity: 1;
}
.Backdrop{
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	position: absolute;
	z-index: 200;
	background: #00000044;
}
.Container{
	top: 20px;
	width: 100%;
	max-width: 600px;
	background: white;
	border-radius: 3px;
	padding: 30px;
	z-index: 400;
	box-shadow: 0 0 40px 0 #00000052;
	transition: all 0.2s;
}
.Dialog.Displayed .Container{
	top: 0px;
}
.CloseButton{
	position: absolute;
    width: 20px;
    height: 20px;
    padding: 5px;
    border: 2px solid #828282;
    border-radius: 50%;
    background: white;
    z-index: 9999;
    right: 10px;
    top: 10px;
    display: none;
}

@media (max-width: 770px){
	.Container{
		width: 100% !important;
	    max-height: calc(100% - 60px);
	    overflow: auto;
		border-radius: 0px;
		margin: 0px 20px;
	}
	.CloseButton{
		display: block;
	}
}