.Text{
	margin: 10px 0px;
}
.AppnameContainer{
	display: flex;
	justify-content: center;
	margin-top: 10px;
	align-items: flex-end;
}
.AppnameContainer button{
	margin: 0px 0px 0px 10px;
	width: 120px;
}
.Form{
	width: 100%;
	margin: auto;
}

@media (max-width: 770px){
	.AppnameContainer{
		display: block;
		width: fit-content;
    	margin: auto;
	}
	.AppnameContainer button{
		width: 100%;
		margin: 10px 0px;
	}
}