.Container {
  width: 100%;
  margin: auto;
  height: 100%;
  display: flex;
  background: white;
  justify-content: center;
  align-items: center;
}
.Alert {
  margin-top: 20px !important;
  text-align: start;
}

.Content {
  height: fit-content;
  margin: 40px auto;
  width: calc(100% - 40px); 
  max-width: 800px;
}

.StoreDetails {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.StoreName {
  width: 200px;
  color: #808080;
}


.Search {
  width: calc(100% - 40px);
  max-width: 600px;
  justify-content: center;
  height: fit-content;
  text-align: center;
  display: flex;
  flex-flow: column;
}

.Search h3 {
  margin-bottom: 10px;
}

.Results {
  display: block;
  height: 100%;
  overflow: auto;
  width: 100%;
  padding: 20px 0px;
}

.SelectedApp {
  margin: 20px auto;
  padding: 10px;
  background-color: #e7efd1;
  display: flex;
  border-radius: 5px;
}

.SelectedApp img {
  height: 50px;
  border-radius: 8px;
  width: 50px;
  margin-right: 12px;
  background: white;
}

.SelectedApp span {
  display: block;
  text-align: left;
  font-size: 17px;
  margin: 4px 0px;
  font-weight: 700;
}

.SelectedApp span:nth-child(2) {
  font-weight: 300;
  font-size: 15px;
}

.Details {
  min-width: 750px;
}

.table {
  overflow: auto;
}

.Details thead tr {
  border-bottom: 2px solid #808080;
}

.Details th {
  padding-bottom: 5px;
}

.Details a {
  text-decoration: none;
  font-weight: 500;
  color: rgb(92, 142, 3);
}

.Details b {
  background: #dad9d9;
  padding: 3px 8px;
  color: white;
  border-radius: 3px;
  width: 130px;
  display: inline-block;
  color: white;
  font-weight: 500;
}
.ShareContainer {
  text-align: center;
  margin: 20px;
}

.ShareContainer a {
  color: #5c8e03;
  font-size: 23px;
  text-decoration: none;
}

.AL {
  text-align: left !important;
}
.AC {
  text-align: center !important;
}
.AR {
  text-align: right !important;
}

.not_found,
.found {
  background: #a2cdf3 !important;
}

.found {
  background: #fd8181 !important;
}

@media (max-width: 750px) {
  .Content {
    max-width: calc(100% - 20px);
    width: calc(100% - 20px);
    margin: 10px;
  }

  .table {
    overflow-x: scroll;
  }
}
.error {
  background: orange !important;
}
