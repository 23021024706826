.error input, .error textarea{
	border: 1px solid var(--error-color);
}
.error p{
	color: var(--error-color);
    margin: 0px;
    font-size: 11px !important;
    text-align: left;
}
.label{
    margin: 0px!important;
    font-size: 11px !important;
    text-align: left;
    color: var(--aic-secondary);
    font-weight: 500;
}