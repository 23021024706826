.App {
    text-align: center;
  }
  
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
    height: 40vmin;
    pointer-events: none;
  }
  
  .App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
  }
  
  .App-link {
    color: #61dafb;
  }
  
  @-webkit-keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
  @keyframes App-logo-spin {
    from {
      transform: rotate(0deg);
    }
    to {
      transform: rotate(360deg);
    }
  }
  
.Login_BottomOptions__30BKX{
	display: flex;
	justify-content: space-between;
	margin: 20px 0px 0px;
}
.Login_BottomOptions__30BKX *{
	color: gray;
	text-decoration: none;
	cursor: pointer;
	transition: all 0.2s;
}
.Login_BottomOptions__30BKX *:hover{
	color: #7ba932;
}
.Login_Title__ZlEBE{
	margin: 25px;
	color: #9E9E9E
}
.LoginForm_Form__2tE7a{
	background: white;
	width: 100%;
	max-width: 400px;
	text-align: center;
	display: block;
	border-radius: 3px;
	z-index: 40;
}

.LoginForm_Form__2tE7a > img{
	width: 100px;
	margin: 50px auto 20px;
	display: block;
}

.LoginForm_Form__2tE7a > div{
	padding: 0px 30px 25px;
}

.LoginForm_Container__12TU5{
	display: flex;
	align-items: center;
	justify-content: center;
	min-height: 100vh;
	width: 100%;
	z-index: 35;
}
.FullBackground_Background__2IvWh{
	position: fixed;
	z-index: 30;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	background: radial-gradient(circle at 100% 0%, #7ba932, #01594d);
}
.Button_Button__1-aMf{
	background: #8dbf40;
    color: white;
    font-size: 18px;
    height: 50px;
    width: 100px;
    border: none;
    box-shadow: 0px 3px 20px 0px #67676733;
    transition: all 0.2s;
    font-weight: 700;
    outline: none;
    cursor: pointer;
}
.Button_Button__1-aMf:not(.Button_Loading__2vg9D):hover{
	background: #7da939;
}
.Button_White__YSQG4{
    background: white;
    color: #8dbf40;
    border:1px solid #efefef;
}
.Button_White__YSQG4:hover{
    background: whitesmoke !important;
}
.Button_Ghost__RuRNg{
    background: #ffffff00;
    border: 2px solid #8dbf40 !important;
    border-radius: 3px;
    color: #8dbf40;
    box-shadow: none;
}
.Button_Ghost__RuRNg:hover{
    background: #8dbf40 !important;
    color: white;
}
.Button_Round__3pQKn{
    width: 50px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Button_Round__3pQKn * {
    -webkit-filter: brightness(10);
            filter: brightness(10);
}
.Button_Payment__NJbhK{
    width: 245px;
    height: 35px;
    border-radius: 4px;
    box-shadow: none;
    background: gray;
    font-size: 14px;
    transition: all 0s;
}
.Button_Payment__NJbhK:hover{
    background: #9f9f9f !important;
}
.Button_Delete__310_c{
    background: #ef5659;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    width: 50px;
}
.Button_Delete__310_c:hover{
    background: #d23f42 !important;
}
.Button_Delete__310_c > *{
    -webkit-filter: brightness(10);
            filter: brightness(10);
}
.Button_Plain__l-RDm{
    border: none !important;
    height: auto !important;
    width: auto !important;
    color: gray;
    background: none !important;
    box-shadow: none !important;
    margin: 10px auto;
    text-decoration: underline;
    font-weight: 500;
}

@media (max-width: 770px){
    .Button_Button__1-aMf{
        height: 38px;
        margin: 0 3px;
    }
    .Button_Round__3pQKn{
        width: 38px;
    }
    .Button_Delete__310_c {
        width: 38px;
    }
}
.Preloader_Loader__1OSik,
.Preloader_Loader__1OSik:before,
.Preloader_Loader__1OSik:after {
  border-radius: 50%;
}
.Preloader_Loader__1OSik {
  color: #8dbd40;
  font-size: 11px;
  text-indent: -99999em;
  margin: 0 auto;
  position: relative;
  width: 10em;
  height: 10em;
  box-shadow: inset 0 0 0 1em;
  transform: translateZ(0);
}
.Preloader_Loader__1OSik:before,
.Preloader_Loader__1OSik:after {
  position: absolute;
  content: '';
}
.Preloader_Loader__1OSik:before {
  width: 5.2em;
  height: 10.2em;
  background: white;
  border-radius: 10.2em 0 0 10.2em;
  top: -0.1em;
  left: -0.1em;
  transform-origin: 5.2em 5.1em;
  -webkit-animation: Preloader_load2__3kxyz 1.33s infinite ease 1s;
          animation: Preloader_load2__3kxyz 1.33s infinite ease 1s;
}
.Preloader_Loader__1OSik:after {
  width: 5.2em;
  height: 10.2em;
  background: white;
  border-radius: 0 10.2em 10.2em 0;
  top: -0.1em;
  left: 5.1em;
  transform-origin: 0px 5.1em;
  -webkit-animation: Preloader_load2__3kxyz 1.33s infinite ease;
          animation: Preloader_load2__3kxyz 1.33s infinite ease;
}
.Preloader_Inverse__3arXP {
  color:white;
}
.Preloader_Dark__-8rzp:before, .Preloader_Dark__-8rzp:after{
  background: black;
}
.Preloader_Inverse__3arXP:before, .Preloader_Inverse__3arXP:after{
  background: #8dbd40;
}
@-webkit-keyframes Preloader_load2__3kxyz {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
@keyframes Preloader_load2__3kxyz {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.AICON_Container__7DNUi {
  width: 100%;
  margin: auto;
  height: 100%;
  display: flex;
  background: white;
  justify-content: center;
  align-items: center;
}
.AICON_Alert__2iC-w {
  margin-top: 20px !important;
  text-align: start;
}

.AICON_Content__23O0Y {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  margin: 40px auto;
  width: calc(100% - 40px); 
  max-width: 800px;
}

.AICON_StoreDetails__1qtr9 {
  display: flex;
  justify-content: space-between;
  width: 100%;
}

.AICON_StoreName__3JBjU {
  width: 200px;
  color: #808080;
}


.AICON_Search__2mb5N {
  width: calc(100% - 40px);
  max-width: 600px;
  justify-content: center;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  text-align: center;
  display: flex;
  flex-flow: column;
}

.AICON_Search__2mb5N h3 {
  margin-bottom: 10px;
}

.AICON_Results__2EPnK {
  display: block;
  height: 100%;
  overflow: auto;
  width: 100%;
  padding: 20px 0px;
}

.AICON_SelectedApp__36KGI {
  margin: 20px auto;
  padding: 10px;
  background-color: #e7efd1;
  display: flex;
  border-radius: 5px;
}

.AICON_SelectedApp__36KGI img {
  height: 50px;
  border-radius: 8px;
  width: 50px;
  margin-right: 12px;
  background: white;
}

.AICON_SelectedApp__36KGI span {
  display: block;
  text-align: left;
  font-size: 17px;
  margin: 4px 0px;
  font-weight: 700;
}

.AICON_SelectedApp__36KGI span:nth-child(2) {
  font-weight: 300;
  font-size: 15px;
}

.AICON_Details__1IZA7 {
  min-width: 750px;
}

.AICON_table__8b1ll {
  overflow: auto;
}

.AICON_Details__1IZA7 thead tr {
  border-bottom: 2px solid #808080;
}

.AICON_Details__1IZA7 th {
  padding-bottom: 5px;
}

.AICON_Details__1IZA7 a {
  text-decoration: none;
  font-weight: 500;
  color: rgb(92, 142, 3);
}

.AICON_Details__1IZA7 b {
  background: #dad9d9;
  padding: 3px 8px;
  color: white;
  border-radius: 3px;
  width: 130px;
  display: inline-block;
  color: white;
  font-weight: 500;
}
.AICON_ShareContainer__VrHON {
  text-align: center;
  margin: 20px;
}

.AICON_ShareContainer__VrHON a {
  color: #5c8e03;
  font-size: 23px;
  text-decoration: none;
}

.AICON_AL__3of9p {
  text-align: left !important;
}
.AICON_AC__328UQ {
  text-align: center !important;
}
.AICON_AR__2o7ca {
  text-align: right !important;
}

.AICON_not_found__1RDzK,
.AICON_found__2MYUp {
  background: #a2cdf3 !important;
}

.AICON_found__2MYUp {
  background: #fd8181 !important;
}

@media (max-width: 750px) {
  .AICON_Content__23O0Y {
    max-width: calc(100% - 20px);
    width: calc(100% - 20px);
    margin: 10px;
  }

  .AICON_table__8b1ll {
    overflow-x: scroll;
  }
}
.AICON_error__13RU5 {
  background: orange !important;
}

.GoogleSearcher_Text__2JGn0{
	margin: 10px 0px;
}
.GoogleSearcher_AppnameContainer__2fW-4{
	display: flex;
	justify-content: center;
	margin-top: 10px;
	align-items: flex-end;
}
.GoogleSearcher_AppnameContainer__2fW-4 button{
	margin: 0px 0px 0px 10px;
	width: 120px;
}
.GoogleSearcher_Form__hYQzj{
	width: 100%;
	margin: auto;
}

@media (max-width: 770px){
	.GoogleSearcher_AppnameContainer__2fW-4{
		display: block;
		width: -webkit-fit-content;
		width: -moz-fit-content;
		width: fit-content;
    	margin: auto;
	}
	.GoogleSearcher_AppnameContainer__2fW-4 button{
		width: 100%;
		margin: 10px 0px;
	}
}
.Input_error__3FZjh input, .Input_error__3FZjh textarea{
	border: 1px solid var(--error-color);
}
.Input_error__3FZjh p{
	color: var(--error-color);
    margin: 0px;
    font-size: 11px !important;
    text-align: left;
}
.Input_label__j7jgv{
    margin: 0px!important;
    font-size: 11px !important;
    text-align: left;
    color: var(--aic-secondary);
    font-weight: 500;
}
.GoogleAppsDialog_Row__2AxEc{
	display: flex;
	background: whitesmoke;
	margin: 10px 0px;
	padding: 10px;
	border-radius: 3px;
	cursor: pointer;
	transition: all 0.2s;
}
.GoogleAppsDialog_Row__2AxEc:hover{
	background: #f0f0f0;
}
.GoogleAppsDialog_Row__2AxEc > img{
	display: block;
	width: 50px;
	height: 50px;
	border-radius: 5px;
	margin-right: 10px;
	background: white;
}
.GoogleAppsDialog_Row__2AxEc > div{
	display: grid;
}
.GoogleAppsDialog_Row__2AxEc > div span{
	display: flex;
	align-items: center;
	padding: 0px 5px;
	font-weight: 700;
	text-align: left;
}
.GoogleAppsDialog_Company__25xTX{
	font-size: 12px;
	font-weight: 300 !important;
}
.GoogleAppsDialog_Title__1WpH0{
	margin: 10px;
	text-align: center;
}
.GoogleAppsDialog_NotMyApp__eVasV{
	background: #c2c2c2;
	justify-content: center;
}
.GoogleAppsDialog_Loader__3vjKl{
	padding: 40px 0px;
}
.GoogleAppsDialog_Loader__3vjKl p{
	text-align: center;
	padding: 10px 0px;
}

.Dialog_Dialog__Hdp_X{
	position: fixed;
	z-index: 1000;
	width: 100vw !important;
	height: 100vh;
	left: 0;
	opacity: 0;
	visibility: hidden;
	top: 0;
	transition: all 0.3s;
	display: flex;
	align-items: center;
	justify-content: center !important;
	transition: all 0.2s;
}
.Dialog_Dialog__Hdp_X.Dialog_Displayed__1GRxt{
	visibility: visible;
	opacity: 1;
}
.Dialog_Backdrop__1gRJ4{
	width: 100%;
	height: 100%;
	top: 0;
	left: 0;
	position: absolute;
	z-index: 200;
	background: #00000044;
}
.Dialog_Container__t7BaN{
	top: 20px;
	width: 100%;
	max-width: 600px;
	background: white;
	border-radius: 3px;
	padding: 30px;
	z-index: 400;
	box-shadow: 0 0 40px 0 #00000052;
	transition: all 0.2s;
}
.Dialog_Dialog__Hdp_X.Dialog_Displayed__1GRxt .Dialog_Container__t7BaN{
	top: 0px;
}
.Dialog_CloseButton__2s3Vs{
	position: absolute;
    width: 20px;
    height: 20px;
    padding: 5px;
    border: 2px solid #828282;
    border-radius: 50%;
    background: white;
    z-index: 9999;
    right: 10px;
    top: 10px;
    display: none;
}

@media (max-width: 770px){
	.Dialog_Container__t7BaN{
		width: 100% !important;
	    max-height: calc(100% - 60px);
	    overflow: auto;
		border-radius: 0px;
		margin: 0px 20px;
	}
	.Dialog_CloseButton__2s3Vs{
		display: block;
	}
}
.LineCard_Card__3pgOq{
    display: block;
    width: 100%;
    height: 100%;
    position: relative;
    border: 1px solid gainsboro;
    border-radius: 5px;
    padding: 15px 15px 10px;
    text-align: left;
    background: white;
    min-height: 200px;
}
.LineCard_Card__3pgOq h4{
    color: var(--aic-green);
    font-weight: 500;
    padding-bottom: 10px;
}
.LineCard_Card__3pgOq p{
    margin: 0px 0px 20px;
}
.LineCard_Card__3pgOq table{
    border-collapse: collapse;
    width: 100%;
}
.LineCard_Card__3pgOq td{
    text-align: center;
    padding: 7px 0px;
}
.LineCard_Card__3pgOq tr:not(:last-child){
    border-bottom: 1px solid gainsboro;
}
.LineCard_Card__3pgOq td:first-child{
    text-align: left;
}
.LineCard_Card__3pgOq td:last-child{
    text-align: right;
    font-weight: 500;
}
.LineCard_description__2_7H6{
    margin-bottom: 10px !important;
}
.LineCard_loader__1WwI_{
    background: white;
    position: absolute;
    width: 100%;
    height: calc(100% - 50px);
    left: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 800;
}

@media (max-width: 650px){
    .LineCard_FullWidthTable__3qYty {
        width: calc(100vw - 15px);
        overflow-x: scroll;
    }

    .LineCard_FullWidthTable__3qYty table {
        width: 750px;
        overflow-x: scroll;
    }
}
.src-components-Welcome-Welcome-module__Container--2blOQ {
  width: 100%;
  padding: 20px 30px;
}
.src-components-Welcome-Welcome-module__EmphasisDark--1f0VI,
.src-components-Welcome-Welcome-module__EmphasisLight--1oIUq {
  padding: 4px 8px;
  background: #5c8e03;
  color: white;
  border-radius: 3px;
  width: 120px;
  display: block;
  float: right;
}
.src-components-Welcome-Welcome-module__EmphasisLight--1oIUq {
  background: #d5f1aa;
  color: black;
}
.src-components-Welcome-Welcome-module__CardsRow--3GcxF {
  display: grid;
  grid-template-columns: repeat(3, calc(33.33% - 10px));
  justify-content: space-between;
  grid-row-gap: 16px;
}
.src-components-Welcome-Welcome-module__TwoCardsRow--3cM0x {
  display: grid;
  grid-template-columns: calc(66.66% - 8px) calc(33.33% - 8px);
  justify-content: space-between;
  grid-gap: 16px;
  padding-top: 15px;
}
.src-components-Welcome-Welcome-module__ChartIcon--27Paz {
  position: absolute;
  right: 10px;
  top: 4px;
  cursor: pointer;
}
.src-components-Welcome-Welcome-module__ChartIcon--27Paz b {
  font-weight: 500;
  color: #b7b7b7;
}
.src-components-Welcome-Welcome-module__ChartIcon--27Paz svg {
  -webkit-filter: brightness(3.2) sepia(1) hue-rotate(60deg);
          filter: brightness(3.2) sepia(1) hue-rotate(60deg);
  top: 6px;
}
.src-components-Welcome-Welcome-module__picker--17Ujp {
  background: white;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  box-shadow: 0px 0px 14px 0px #0000000d;
  border: 1px solid #f1f1f1;
}
.src-components-Welcome-Welcome-module__pickercontainer--MllqI {
  margin-bottom: 20px;
}
.src-components-Welcome-Welcome-module__finance--3RqPx .ant-card-body {
  height: 370px !important;
}
.src-components-Welcome-Welcome-module__churnedList--CiRcM {
  display: block;
  font-size: 12px;
  margin-top: 5px;
  max-width: 700px;
  padding: 8px;
  background: aliceblue;
}
@media (max-width: 850px) {
  .src-components-Welcome-Welcome-module__CardsRow--3GcxF,
  .src-components-Welcome-Welcome-module__TwoCardsRow--3cM0x {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
  }
}
@media (max-width: 480px) {
  .src-components-Welcome-Welcome-module__CardsRow--3GcxF,
  .src-components-Welcome-Welcome-module__TwoCardsRow--3cM0x {
    grid-template-columns: repeat(1, 1fr);
  }
}

.ScrollTable_fixed_header__1p7Xr{
    width: 100%;
    table-layout: fixed;
    border-spacing: 0;
    border-radius: 5px;    
    margin-bottom: 10px;
}

.ScrollTable_fixed_header__1p7Xr th {
    color: #000000;
    border-bottom: 2px solid gray;
    padding: 7px 12px 7px 0px !important;
    width: 100%;
    font-size: 16px;
    height: 32px;
    font-weight: 500;
    box-sizing: border-box;
}

.ScrollTable_fixed_header__1p7Xr tr{
  display: flex;
}

.ScrollTable_fixed_header__1p7Xr tbody{
  display:block;
  width: 100%;
  overflow: auto;
  height: 180px;
  padding-top: 3px;
}

.ScrollTable_fixed_header__1p7Xr tbody::-webkit-scrollbar {
    width: 7px;
}
.ScrollTable_fixed_header__1p7Xr tbody::-webkit-scrollbar-track {
    background: transparent; 
}
.ScrollTable_fixed_header__1p7Xr tbody::-webkit-scrollbar-thumb {
    background: #88888866; 
    border-radius: 50px;
}

.ScrollTable_fixed_header__1p7Xr thead tr {
   display: flex;
}

.ScrollTable_fixed_header__1p7Xr td {
  padding: 5px 12px 5px 0px !important;
  width: 100%;
  border-bottom: 1px solid lightgray;
}
.ScrollTable_fixed_header__1p7Xr tr td, .ScrollTable_fixed_header__1p7Xr tr th{
  text-align: center;
}
.ScrollTable_fixed_header__1p7Xr tr td:first-child, .ScrollTable_fixed_header__1p7Xr tr th:first-child{
  text-align: left;
}
.ScrollTable_fixed_header__1p7Xr tr td:last-child, .ScrollTable_fixed_header__1p7Xr tr th:last-child{
  text-align: right;
}

@media (max-width: 1150px){
  .ScrollTable_fixed_header__1p7Xr th {
    height: unset;
  }
}
.src-components-Welcome-TransactionsChart-PieChart-PieChart-module__chart--3Lwv7 {
  position: relative;
  bottom: 30px;
  right: 7%;
  height: 240px;
  width: 350px;
}

.src-components-NewAppDistribution-NewAppDistribution-module__container--1txdz {
  padding: 20px 20px 8px;
  text-align: left;
}
.src-components-NewAppDistribution-NewAppDistribution-module__container--1txdz h3 {
  color: #97c549;
  font-weight: 800;
  margin-bottom: 0px;
  margin-top: 8px;
}
.src-components-NewAppDistribution-NewAppDistribution-module__storeFilter--2-nOV {
  position: relative;
  width: calc(100% - 420px) !important;
}
.src-components-NewAppDistribution-NewAppDistribution-module__card--1bM56 {
  margin-top: 20px !important;
}
.src-components-NewAppDistribution-NewAppDistribution-module__card--1bM56 .ant-card-body {
  padding: 12px;
}
.src-components-NewAppDistribution-NewAppDistribution-module__card--1bM56 .ant-table-wrapper {
  margin-bottom: 8px;
}
.src-components-NewAppDistribution-NewAppDistribution-module__card--1bM56 .ant-table-tbody td {
  padding: 8px 2px !important;
}
.src-components-NewAppDistribution-NewAppDistribution-module__card--1bM56 .ant-table-content {
  border: none !important;
}
.src-components-NewAppDistribution-NewAppDistribution-module__title--1c0MW {
  margin-bottom: 20px;
}
.src-components-NewAppDistribution-NewAppDistribution-module__globalSave--3ROND {
  position: fixed !important;
  top: 64px;
  right: 295px;
  z-index: 90;
}

.src-components-NewAppDistribution-HistoryTable-HistoryTable-module__container--JuC9p .ant-table-content {
  overflow-x: scroll;
}
.src-components-NewAppDistribution-HistoryTable-HistoryTable-module__container--JuC9p .ant-checkbox-wrapper {
  padding: 13px;
}
.src-components-NewAppDistribution-HistoryTable-HistoryTable-module__storeHiddenCheckbox--3Z2TP {
  border: 1px solid #efef45;
  border-radius: 4px;
  display: block !important;
  background: #fffffa;
}
.src-components-NewAppDistribution-HistoryTable-HistoryTable-module__Form--K62A9 {
  padding: 10px;
  max-width: 700px;
}
.src-components-NewAppDistribution-HistoryTable-HistoryTable-module__Form--K62A9 > div {
  display: grid;
  grid-gap: 10px;
  grid-template-columns: 1fr 2fr;
  margin-bottom: 10px;
}
.src-components-NewAppDistribution-HistoryTable-HistoryTable-module__Form--K62A9 > div label {
  margin: 0px !important;
  color: rgba(0, 0, 0, 0.85) !important;
  font-size: 14px !important;
}
.src-components-NewAppDistribution-HistoryTable-HistoryTable-module__Form--K62A9 > div div .ant-tag {
  max-width: -webkit-fit-content;
  max-width: -moz-fit-content;
  max-width: fit-content;
}

.src-components-NewAppDistribution-GeneralInfoCard-GeneralInfoCard-module__container--1Pqwx {
  max-width: 700px;
}
.src-components-NewAppDistribution-GeneralInfoCard-GeneralInfoCard-module__container--1Pqwx .ant-col.ant-form-item-label label {
  position: relative;
  display: block;
  width: 100%;
  text-align: left;
}

.src-components-AppDetailsDistribution-AppDistribution-module__container--3dMyO {
  padding: 20px 24px 24px;
  position: relative;
}
.src-components-AppDetailsDistribution-AppDistribution-module__save--OGfEz {
  padding: 20px 0px;
  display: flex;
  justify-content: flex-end;
}
.src-components-AppDetailsDistribution-AppDistribution-module__globalSave--1OdSa {
  position: fixed !important;
  top: 64px;
  right: 295px;
  z-index: 90;
}
.src-components-AppDetailsDistribution-AppDistribution-module__left--BiVI4 {
  text-align: left !important;
}

.src-components-AppDetailsDistribution-GeneralRemarks-GeneralRemarks-module__container--4TkNS .ant-col-sm-16 {
  width: 100%;
}

.src-components-AppDetailsDistribution-ClientsLinked-ClientsLinked-module__blocked--nk981 {
  display: flex !important;
}

.src-components-AppDetailsDistribution-ClientsLinked-UserTable-UserTable-module__container--2QGJ1 {
  margin-bottom: 20px;
}

.src-components-AppDetailsDistribution-SalesSection-SalesSection-module__container--22OFK {
  max-width: 720px;
}
.src-components-AppDetailsDistribution-SalesSection-SalesSection-module__container--22OFK .ant-col.ant-form-item-label label {
  position: relative;
  display: block;
  width: 100%;
  text-align: left;
}
.src-components-AppDetailsDistribution-SalesSection-SalesSection-module__container--22OFK .ant-input-number {
  width: 100%;
}

.File_Container__3HlqQ{
	width: 100%;
	height: 110px;
	border-radius: 8px;
	text-align: center;
    border: 1px dashed lightgray;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	overflow: hidden;
}
.File_Container__3HlqQ div{
	/*position: relative;*/
}
.File_Container__3HlqQ img{
	width: 30px;
	height: 30px;
	-webkit-filter: unset;
	        filter: unset;
	transition: all 0s;
}
.File_Container__3HlqQ:not(.File_Disabled__2YZVV):hover .File_Icon__Wmuhg, .File_Container__3HlqQ.File_HasFiles__CCxYt .File_Icon__Wmuhg{
	-webkit-filter: sepia(1) hue-rotate(50deg) brightness(1.25) saturate(1.7);
	        filter: sepia(1) hue-rotate(50deg) brightness(1.25) saturate(1.7);
}
.File_Container__3HlqQ p{
	padding: 0px 5px;
	color: inherit;
	transition: all 0s;
	font-size: 14px;
	line-height: 19px;
}
.File_Container__3HlqQ p b{
	margin: 5px 10px;
	padding: 1px 5px;
	border-radius: 3px;
	background: #5c7d2b;
	color: white;
	z-index: 900;
	cursor: pointer;
	display: inline-block;
}
.File_Container__3HlqQ:not(.File_Disabled__2YZVV):hover p, .File_Container__3HlqQ.File_HasFiles__CCxYt p{
	color: #98cc6b;
}
.File_Container__3HlqQ input{
	position: absolute;
	opacity: 0;
	width: 100%;
	height: 100%;
	left: 0px;
	top: 0px;
	z-index: 100;
	cursor: pointer;
}
.File_Invalid__wWysM img{
	-webkit-filter: sepia(1) hue-rotate(310deg) brightness(1.25) saturate(4);
	        filter: sepia(1) hue-rotate(310deg) brightness(1.25) saturate(4);
}
.File_Invalid__wWysM p{
	color: #ff868a;
}
.File_Modified__fMj9c{
	background-color: #fddcc5 !important;
}
.File_Modified__fMj9c p{
	color:#000000 !important;
}
.File_Modified__fMj9c img{
	-webkit-filter: brightness(0.8) !important;
	        filter: brightness(0.8) !important;
}
.File_Modified__fMj9c b{
	background: #626262 !important;
}
.File_DisabledFilter__3WA95{
	background: transparent;
	position: absolute !important;
	opacity: 0;
	width: 100%;
	height: 100%;
	left: 0px;
	top: 0px;
	z-index: 200;
}
.File_icons__26Teg{
	display: flex;
	justify-content: center;
	align-items: center;
	visibility: hidden;
	opacity: 0;
	position: absolute;
	height: 100%;
	width: 100%;
	left: 0px;
	top: 0px;
	z-index: 700;
	transition: all 0.2s;
	background: #ffffffcc; 
}
.File_Container__3HlqQ:hover .File_icons__26Teg{
	visibility: visible;
	opacity: 1;
}
.File_icon__3-tyu{
	border: 1px solid #757575;
    border-radius: 50px;
    height: 24px;
    width: 25px;
    display: inline-flex;
    justify-content: center;
	align-items: center;
	margin: 0px 3px 0px;
}
.File_icon__3-tyu > *{
	fill: #757575;
}
.File_deleteIcon__oEos6 {
	width: 16px;
	height: 16px;
	position: absolute;
	right: 5px;
	top: 5px;
	background: #9e9e9e;
	opacity: 0;
	visibility: hidden;
	transition: all 0.2s;
}
.File_Container__3HlqQ:not(.File_Disabled__2YZVV):hover .File_deleteIcon__oEos6{
	opacity: 1;
	visibility: visible;
}
.File_Container__3HlqQ p{
	margin: 0px;
}
.File_Container__3HlqQ a{
	top: 1px;
    position: relative;
}
.src-components-AppDetailsDistribution-DistributionSection-DistributionSection-module__container--1lT-B {
  max-width: 720px;
}
.src-components-AppDetailsDistribution-DistributionSection-DistributionSection-module__container--1lT-B .ant-col.ant-form-item-label label {
  position: relative;
  display: block;
  width: 100%;
  text-align: left;
}
.src-components-AppDetailsDistribution-DistributionSection-DistributionSection-module__checkbox--2lqjt {
  margin: 0px;
  text-align: left;
}
.src-components-AppDetailsDistribution-DistributionSection-DistributionSection-module__checkbox--2lqjt p {
  margin: 0 !important;
  padding-left: 10px;
}
.src-components-AppDetailsDistribution-DistributionSection-DistributionSection-module__checkbox--2lqjt .ant-form-item-children {
  display: flex;
}

.src-components-AppDetailsDistribution-OperationsDetailsSection-OperationsDetailsSection-module__container--BK15L {
  max-width: 720px;
}
.src-components-AppDetailsDistribution-OperationsDetailsSection-OperationsDetailsSection-module__container--BK15L .ant-col.ant-form-item-label label {
  position: relative;
  display: block;
  width: 100%;
  text-align: left;
}

.src-components-UI-PreviewButton-PreviewButton-module__Container--3EDnu {
  width: 100%;
  border-radius: 6px;
  /* background: #f6f6f6; */
  padding: 8px;
  /* display: flex;	
	justify-content: space-between;
	align-items: center; */
  height: 40px;
}
/* .Container > div{
	display: flex;
	align-items: center;
	width: 62px;
	justify-content: space-between;
} */
.src-components-UI-PreviewButton-PreviewButton-module__Container--3EDnu a {
  display: flex;
  align-items: center;
}
.src-components-UI-PreviewButton-PreviewButton-module__Container--3EDnu svg {
  opacity: 0.9;
}
.src-components-UI-PreviewButton-PreviewButton-module__label--3wmvL {
  margin: 0 !important;
  font-size: 16px;
}
.src-components-UI-PreviewButton-PreviewButton-module__modified--3yZ4o,
.src-components-UI-PreviewButton-PreviewButton-module__modified--3yZ4o label {
  background: #fddcc5;
}
.src-components-UI-PreviewButton-PreviewButton-module__document--m_58X {
  overflow: auto;
  height: 700px;
}
.src-components-UI-PreviewButton-PreviewButton-module__document--m_58X .react-pdf__Document {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.src-components-UI-PreviewButton-PreviewButton-module__document--m_58X .react-pdf__Page {
  margin: 10px 0px 10px;
  box-shadow: 0px 0px 12px 0px #0000002e;
  border: 1px solid #e2e2e2;
}

.Tooltip_Container__16Zsn{
	display: inline-flex;
	height: auto;
	align-items: center;
	width: auto;
	text-align: center;
	margin: 0px;
	transition: all 0.3s;
}
.Tooltip_Tooltip__2F7jy{
	display: block;
	position: fixed;
	min-width: 200px;
	background: #2f2f2f;
	color: white;
	border-radius: 4px;
	padding: 15px;
	opacity: 0;
	visibility: hidden;
	transition: all 0.2ss;
	z-index: 500;
	top: calc(100% + 10px);
	transform: translateX(-50%);
	left: 50%;
	font-weight: 300 !important;
}
.Tooltip_Tooltip__2F7jy span{
	color:white;
}
.Tooltip_Tooltip__2F7jy a{
	color: #81bbef;
	display: inline-block;
}
.Tooltip_Tooltip__2F7jy img{
	position: relative;
	width: 100%!important;
	height: 100%!important;
}
.Tooltip_Tooltip__2F7jy i{
	display: block;
	width: 0;
	height: 0;
	border: 10px solid #00000000;
	border-bottom: 10px solid black;
	position: absolute;
	left: calc(50% - 10px);
	top: -20px;
}
.Tooltip_Container__16Zsn:hover .Tooltip_Tooltip__2F7jy{
	opacity: 1;
	visibility: visible;
	transition: all 0s;
}
.src-components-AppDetailsDistribution-Contracts-ContractActions-ContractActions-module__previewButton--Kml2b .ant-btn {
  top: -8px;
  left: -6px;
}
.src-components-AppDetailsDistribution-Contracts-ContractActions-ContractActions-module__downloadButton--zr42o .ant-btn {
  left: -13px;
}

.src-components-AppDocuments-AppDocuments-module__container--1k5c3 {
  padding: 30px;
}
.src-components-AppDocuments-AppDocuments-module__container--1k5c3 h3 {
  margin: 10px 0px 0px;
  color: #8dbe40;
  font-weight: 700;
}
.src-components-AppDocuments-AppDocuments-module__header--1BZkq {
  margin-bottom: 20px;
}

.src-components-AppDocuments-AttachmentCard-AttachmentCard-module__container--1oNos {
  padding: 20px 40px 20px 0px;
}
.src-components-AppDocuments-AttachmentCard-AttachmentCard-module__Button--1U_qi {
  margin-bottom: 20px;
  margin-top: 20px;
}

.src-components-AppHistory-AppHistory-module__container--2qCRu {
  padding: 22px;
  text-align: left;
}
.src-components-AppHistory-AppHistory-module__container--2qCRu .ant-tabs {
  background-color: white;
  display: grid;
  grid-template-columns: 0px 200px calc(100% - 202px);
}
.src-components-AppHistory-AppHistory-module__container--2qCRu .ant-tabs-nav .ant-tabs-tab-active {
  background-color: #f7ffeb;
  font-weight: bold;
}
.src-components-AppHistory-AppHistory-module__container--2qCRu .ant-tabs-content {
  margin: 20px;
}
.src-components-AppHistory-AppHistory-module__container--2qCRu .ant-tabs-bar {
  border-right: 2px solid #e8e8e8 !important;
}
.src-components-AppHistory-AppHistory-module__container--2qCRu .ant-table-wrapper {
  border-top: 1px solid gainsboro;
}
.src-components-AppHistory-AppHistory-module__container--2qCRu .ant-tabs-tabpane.ant-tabs-tabpane-active {
  padding: 20px;
}
@media (max-width: 700px) {
  .src-components-AppHistory-AppHistory-module__container--2qCRu .ant-tabs.ant-tabs-top.ant-tabs-line {
    display: flex;
    flex-direction: column;
  }
}

.src-components-UI-ClientsFile-File-module__Container--1NDfA {
  width: 100%;
  height: 110px;
  border-radius: 8px;
  text-align: center;
  border: 1px dashed lightgray;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  overflow: hidden;
  background-color: white;
}
.src-components-UI-ClientsFile-File-module__Container--1NDfA img {
  width: 30px;
  height: 30px;
  -webkit-filter: unset;
          filter: unset;
  transition: all 0s;
}
.src-components-UI-ClientsFile-File-module__preview--2orD6 {
  position: absolute;
  width: 100% !important;
  height: 100% !important;
  left: 0px;
  top: 0px;
  object-fit: cover;
  z-index: 100;
}
.src-components-UI-ClientsFile-File-module__Container--1NDfA:not(.src-components-UI-ClientsFile-File-module__Disabled--247dG):hover .src-components-UI-ClientsFile-File-module__Icon--1jtko,
.src-components-UI-ClientsFile-File-module__Container--1NDfA.src-components-UI-ClientsFile-File-module__HasFiles--2VWFp .src-components-UI-ClientsFile-File-module__Icon--1jtko {
  -webkit-filter: sepia(1) hue-rotate(50deg) brightness(1.25) saturate(1.7);
          filter: sepia(1) hue-rotate(50deg) brightness(1.25) saturate(1.7);
}
.src-components-UI-ClientsFile-File-module__Container--1NDfA p {
  padding: 0px 5px;
  color: inherit;
  transition: all 0s;
  font-size: 14px;
  line-height: 19px;
}
.src-components-UI-ClientsFile-File-module__Container--1NDfA p b {
  margin: 5px 10px;
  padding: 1px 5px;
  border-radius: 3px;
  background: #5c7d2b;
  color: white;
  z-index: 900;
  cursor: pointer;
  display: inline-block;
}
.src-components-UI-ClientsFile-File-module__Container--1NDfA:not(.src-components-UI-ClientsFile-File-module__Disabled--247dG):hover p,
.src-components-UI-ClientsFile-File-module__Container--1NDfA.src-components-UI-ClientsFile-File-module__HasFiles--2VWFp p {
  color: #98cc6b;
}
.src-components-UI-ClientsFile-File-module__Container--1NDfA input {
  position: absolute;
  opacity: 0;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
  z-index: 100;
  cursor: pointer;
}
.src-components-UI-ClientsFile-File-module__preview--2orD6 {
  position: absolute;
  width: 100% !important;
  height: 100% !important;
  left: 0px;
  top: 0px;
  object-fit: cover;
  z-index: 50;
}
.src-components-UI-ClientsFile-File-module__Invalid--37btZ img {
  -webkit-filter: sepia(1) hue-rotate(310deg) brightness(1.25) saturate(4) !important;
          filter: sepia(1) hue-rotate(310deg) brightness(1.25) saturate(4) !important;
}
.src-components-UI-ClientsFile-File-module__Invalid--37btZ p {
  color: #ff868a !important;
}
.src-components-UI-ClientsFile-File-module__textError--9zH-y {
  color: #ff868a !important;
  font-size: 12px !important;
  text-align: left !important;
}
.src-components-UI-ClientsFile-File-module__Modified--DMHeh {
  background-color: #fddcc5 !important;
}
.src-components-UI-ClientsFile-File-module__Modified--DMHeh p {
  color: #000000 !important;
}
.src-components-UI-ClientsFile-File-module__Modified--DMHeh img {
  -webkit-filter: brightness(0.8) !important;
          filter: brightness(0.8) !important;
}
.src-components-UI-ClientsFile-File-module__Modified--DMHeh b {
  background: #626262 !important;
}
.src-components-UI-ClientsFile-File-module__DisabledFilter--2tYbJ {
  background: transparent;
  position: absolute !important;
  opacity: 0;
  width: 100%;
  height: 100%;
  left: 0px;
  top: 0px;
}
.src-components-UI-ClientsFile-File-module__icons--2u39c {
  display: flex;
  justify-content: center;
  align-items: center;
  visibility: hidden;
  opacity: 0;
  position: absolute;
  height: 100%;
  width: 100%;
  left: 0px;
  top: 0px;
  z-index: 70;
  transition: all 0.2s;
  background: #ffffffcc;
}
.src-components-UI-ClientsFile-File-module__Container--1NDfA:hover .src-components-UI-ClientsFile-File-module__icons--2u39c {
  visibility: visible;
  opacity: 1;
}
.src-components-UI-ClientsFile-File-module__icon--15L6R {
  border: 1px solid #757575;
  border-radius: 50px;
  height: 24px;
  width: 25px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  margin: 0px 3px 0px;
}
.src-components-UI-ClientsFile-File-module__icon--15L6R > * {
  fill: #757575;
}
.src-components-UI-ClientsFile-File-module__deleteIcon--1gyFF {
  width: 16px;
  height: 16px;
  position: absolute;
  right: 5px;
  top: 5px;
  background: #9e9e9e;
  opacity: 0;
  visibility: hidden;
  transition: all 0.2s;
}
.src-components-UI-ClientsFile-File-module__Container--1NDfA:not(.src-components-UI-ClientsFile-File-module__Disabled--247dG):hover .src-components-UI-ClientsFile-File-module__deleteIcon--1gyFF {
  opacity: 1;
  visibility: visible;
}
.src-components-UI-ClientsFile-File-module__Container--1NDfA p {
  margin: 0px;
}
.src-components-UI-ClientsFile-File-module__Container--1NDfA a {
  top: 1px;
  position: relative;
}
.src-components-UI-ClientsFile-File-module__CustomIcon--tiXRr {
  border-radius: 4px !important;
  width: 40px !important;
  height: 40px !important;
}
.src-components-UI-ClientsFile-File-module__Container--1NDfA input {
  z-index: 20;
}
.src-components-UI-ClientsFile-File-module__progressContainer--3WYoN {
  z-index: 80;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.src-components-UI-ClientsFile-File-module__inputContainer--1p9ou {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.src-components-UI-ClientsFile-File-module__video--1mhN- {
  position: absolute;
  width: 100% !important;
  height: 100% !important;
  left: 0px;
  top: 0px;
  z-index: 50;
  object-fit: cover;
}

.src-components-AppHistory-Preview-UploadApk-UploadApk-module__container--1Hara .ant-badge {
  width: 100%;
}

.src-components-AppHistory-Preview-AppInformation-AppInformation-module__container--1e4ye .ant-badge {
  width: 100%;
}

.src-components-AppHistory-Preview-Screenshots-Screenshots-module__container--NP1A2 .src-components-AppHistory-Preview-Screenshots-Screenshots-module__b--1yERw {
  text-align: start;
}
.src-components-AppHistory-Preview-Screenshots-Screenshots-module__container--NP1A2 .ant-badge {
  width: 100%;
}
.src-components-AppHistory-Preview-Screenshots-Screenshots-module__description--2oXPb {
  text-align: left;
  margin-top: 15px;
}


.src-components-AppHistory-Preview-DistributionDetails-DistributionDetails-module__container--39V58 .ant-badge {
  width: 100%;
}

.src-components-AppHistory-Preview-PrivacyPolicy-PrivacyPolicy-module__container--2W0Np .ant-badge {
  width: 100%;
}

.src-components-ApkReclaim-ApkReclaim-module__container--1Ph8z {
  padding: 30px;
}
.src-components-ApkReclaim-ApkReclaim-module__globalSave--U2-zw {
  position: fixed !important;
  top: 64px;
  right: 295px;
  z-index: 90;
}

.src-components-ApkReclaim-AttachmentCard-AttachmentCard-module__container--3t_6d {
  padding: 20px 40px 20px 0px;
}
.src-components-ApkReclaim-AttachmentCard-AttachmentCard-module__Button--3bu4g {
  margin-bottom: 20px;
  margin-top: 20px;
}

.src-components-AppLocalization-AppLocalization-module__container--2pTml {
  padding: 16px;
}
.src-components-AppLocalization-AppLocalization-module__container--2pTml h2 {
  text-align: left;
  color: var(--aic-green);
  font-size: 16px;
  padding-top: 20px;
}
.src-components-AppLocalization-AppLocalization-module__card--22JfR {
  text-align: left;
  margin-bottom: 10px !important;
  position: relative;
}
.src-components-AppLocalization-AppLocalization-module__card--22JfR p,
.src-components-AppLocalization-AppLocalization-module__card--22JfR h4 {
  font-size: 14px;
}
.src-components-AppLocalization-AppLocalization-module__card--22JfR h4 {
  margin: 16px 0px 0px;
}
.src-components-AppLocalization-AppLocalization-module__cardDate--29MT7 {
  text-align: right;
  color: gray;
  line-height: 8px;
  right: -5px;
  position: relative;
}

.src-components-AccountsTab-Analytics-module__info--2gP6v {
  margin-top: 5px;
}
.src-components-AccountsTab-Analytics-module__info--2gP6v b {
  font-weight: 600;
}

.src-components-UserAnalytics-UserAnalytics-module__container--256Pe {
  padding: 20px;
  width: 100%;
}
.src-components-UserAnalytics-UserAnalytics-module__appSelect--2NpHX {
  margin-bottom: 20px;
}
.src-components-UserAnalytics-UserAnalytics-module__appSelect--2NpHX h3 {
  margin-left: 10px;
}
.src-components-UserAnalytics-UserAnalytics-module__appSelect--2NpHX .ant-select {
  width: calc(100% - 18px);
}

.src-components-UserAnalytics-AppPromotionCard-AppPromotionCard-module__container--1hdBY h3 {
  text-align: left;
  display: block;
  color: #8dbe40;
}
.src-components-UserAnalytics-AppPromotionCard-AppPromotionCard-module__container--1hdBY .ant-select {
  width: 100%;
  margin-bottom: 20px;
}
.src-components-UserAnalytics-AppPromotionCard-AppPromotionCard-module__globalSave--3nm4b {
  position: fixed !important;
  top: 64px;
  right: 295px;
  z-index: 90;
}

.src-components-PaymentsAndAnalytics-PaymentsAndAnalytics-module__container--2zsN- {
  padding: 22px;
  text-align: left;
}
.src-components-PaymentsAndAnalytics-PaymentsAndAnalytics-module__container--2zsN- .ant-tabs {
  background-color: white;
  display: grid;
  grid-template-columns: 0px 200px calc(100% - 202px);
}
.src-components-PaymentsAndAnalytics-PaymentsAndAnalytics-module__container--2zsN- .ant-tabs-nav .ant-tabs-tab-active {
  background-color: #f7ffeb;
  font-weight: bold;
}
.src-components-PaymentsAndAnalytics-PaymentsAndAnalytics-module__container--2zsN- .ant-tabs-content {
  margin: 20px;
}
.src-components-PaymentsAndAnalytics-PaymentsAndAnalytics-module__container--2zsN- .ant-tabs-bar {
  border-right: 2px solid #e8e8e8 !important;
}
.src-components-PaymentsAndAnalytics-PaymentsAndAnalytics-module__container--2zsN- .ant-table-wrapper {
  border-top: 1px solid gainsboro;
}
.src-components-PaymentsAndAnalytics-PaymentsAndAnalytics-module__container--2zsN- .ant-tabs-tabpane.ant-tabs-tabpane-active {
  padding: 20px;
}
@media (max-width: 700px) {
  .src-components-PaymentsAndAnalytics-PaymentsAndAnalytics-module__container--2zsN- .ant-tabs.ant-tabs-top.ant-tabs-line {
    display: flex;
    flex-direction: column;
  }
}

.src-components-CloudStore-CloudStore-module__container--3zCAp h3 {
  text-align: left;
  color: #97c549;
}

.src-components-CloudStore-CloudBilling-CloudBilling-module__rowTable--3g-31 .ant-table-content {
  background: none !important;
  padding-right: 30px;
  border: none !important;
}
.src-components-CloudStore-CloudBilling-CloudBilling-module__rowTable--3g-31 .ant-table-column-title {
  font-weight: 400;
}

.src-components-AppDetails-AppDetails-module__container--3hmeK .ant-tabs-top > .ant-tabs-content > .ant-tabs-tabpane {
  height: calc(100vh - 155px);
  overflow: auto;
}
.src-components-AppDetails-AppDetails-module__container--3hmeK .ant-tabs-bar {
  margin: 0px;
  text-align: left;
}
.src-components-AppDetails-AppDetails-module__container--3hmeK .ant-tabs-nav .ant-tabs-tab {
  padding: 10px 16px 10px 16px !important;
  margin: 0px !important;
}

.src-components-AppDetails-AppHeader-AppHeader-module__appHeader--3imcl {
  display: flex;
  height: 60px;
  align-items: center;
  padding: 0px 9px;
  border-bottom: 1px solid gainsboro;
  justify-content: space-between;
  position: relative;
  background: white;
}
.src-components-AppDetails-AppHeader-AppHeader-module__appHeader--3imcl .src-components-UI-AppNameEdit-AppName-module__container--3tJHR {
  width: calc(100% - 350px);
  padding: 2px 5px 0px 0px;
  outline: none;
  position: relative;
  top: -5px;
}
.src-components-AppDetails-AppHeader-AppHeader-module__appHeader--3imcl .src-components-AppDetails-AppHeader-AppHeader-module__completion--3979z {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-end;
}
.src-components-AppDetails-AppHeader-AppHeader-module__appHeader--3imcl .src-components-AppDetails-AppHeader-AppHeader-module__completion--3979z span {
  margin: 0 !important;
}
.src-components-AppDetails-AppHeader-AppHeader-module__appId--16_uz {
  font-size: 10px;
  position: absolute;
  left: 70px;
  bottom: 8px;
}

.QRDialog_container__1lrfW{
    margin: 20px auto 20px!important;
}
.QRDialog_container__1lrfW, .QRDialog_link__2EGTH{
    margin: 10px auto 10px;
    display: block;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}
.src-components-UI-AppImageEdit-AppImage-module__imgApp--2cmWD {
  width: 45px;
  height: 45px;
  border: 1px solid #e6e6e6;
  border-radius: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  bottom: 11px;
}
.src-components-UI-AppImageEdit-AppImage-module__imgApp--2cmWD .ant-spin-spinning {
  justify-content: center;
  align-items: center;
  display: flex;
  opacity: 0.4;
}
.src-components-UI-AppImageEdit-AppImage-module__small--2-Amw {
  width: 30px;
  height: 30px;
}
.src-components-UI-AppImageEdit-AppImage-module__container--Anb9W .anticon.anticon-edit > svg {
  fill: transparent;
}
.src-components-UI-AppImageEdit-AppImage-module__container--Anb9W:hover {
  opacity: 0.5;
}
.src-components-UI-AppImageEdit-AppImage-module__container--Anb9W:hover .anticon.anticon-edit > svg {
  position: relative;
  top: 15px;
  left: 15px;
  fill: black;
  z-index: 40000;
}

.src-components-UI-AppNameEdit-AppName-module__container--3tJHR {
  display: flex;
}
.src-components-UI-AppNameEdit-AppName-module__container--3tJHR .anticon {
  margin-top: 6px;
}
.src-components-UI-AppNameEdit-AppName-module__name--NokI5 {
  width: calc(100% - 85px);
  display: block;
  margin-top: -10px;
}
.src-components-UI-AppNameEdit-AppName-module__name--NokI5 input {
  font-weight: 500;
  font-size: 16px;
  border: none;
  z-index: 10;
  width: 100%;
  background: transparent;
}

.src-components-AppProjects-AppProjects-module__container--2Q8Dg {
  padding: 20px;
}
.src-components-AppProjects-AppProjects-module__addButton--VNItp {
  margin-bottom: 20px;
}

.src-components-NewClientsSection-Clients-module__container--1lJV2 {
  padding: 20px;
}
.src-components-NewClientsSection-Clients-module__container--1lJV2 h3 {
  text-align: left;
}
.src-components-NewClientsSection-Clients-module__container--1lJV2 .ant-col.ant-col-6 {
  padding: 3px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
}
.src-components-NewClientsSection-Clients-module__container--1lJV2 .ant-col-sm-12.ant-col-md-9.ant-col-lg-7.ant-col-xl-6 {
  padding-right: 10px;
}
.src-components-NewClientsSection-Clients-module__container--1lJV2 .ant-col.ant-col-md-9.ant-col-lg-12.ant-col-xl-14 {
  padding-right: 10px;
}
@media (max-width: 750px) {
  .src-components-NewClientsSection-Clients-module__container--1lJV2 .ant-table-fixed {
    max-width: 150px;
  }
}

.src-components-UI-AppImage-AppImage-module__imgApp--n_LBk {
  width: 45px;
  overflow: hidden;
  height: 45px;
  border: 1px solid #e6e6e6;
  border-radius: 20%;
  display: flex;
  align-items: center;
  justify-content: center;
}
.src-components-UI-AppImage-AppImage-module__imgApp--n_LBk .ant-spin-spinning {
  justify-content: center;
  align-items: center;
  display: flex;
  opacity: 0.4;
}
.src-components-UI-AppImage-AppImage-module__small--3i9bo {
  width: 30px;
  height: 30px;
}

.src-components-NewClientsSection-NewUserModal-NewUsersModal-module__blocked--5x0_5 {
  display: flex !important;
}

.SearchBar_Container__3WFao{
    background: whitesmoke;
    width: 350px;
    margin-right: 21px;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    transition: width 0.3s;
    border-radius: 5px;
    position: fixed;
    top: 7px;
    right: 90px;
}
.SearchBar_Focused__-c2D6{
	box-shadow: 0px 1px 20px 0px #0000003d;
}
.SearchBar_Container__3WFao svg{
    -webkit-filter: brightness(3);
            filter: brightness(3);
    top: -6px;
    position: absolute;
}
.SearchBar_Container__3WFao > input{
    position: relative;
    width: 100%;
    right: 0px;
    height: 34px;
    border: none;
    background: transparent;
    padding: 5px 5px 5px 40px;
    font-size: 16px;
    font-weight: 300;
    outline: none;
    color: black;
}
.SearchBar_Results__11fGs{
    background: white;
}
.SearchBar_Results__11fGs a{
	display: flex;
	align-items: center;
	justify-content: flex-start;
	padding: 10px;
	text-decoration: none;
}
.SearchBar_Results__11fGs a:hover{
	background: whitesmoke;
}
.SearchBar_Results__11fGs a img{
	width: 30px;
	height: 30px;
	border-radius: 50%;
}
.SearchBar_Results__11fGs a img + span, .SearchBar_Results__11fGs a div + span{
    padding-left: 10px;
}
.SearchBar_Title__2Ftv8{
    width: 100%;
    color: #aaaaaa;
    font-size: 14px;
    padding: 5px 10px 5px;
    display: block;
    font-weight: 700;
    border-top: 1px solid #e0e0e0;
    text-align: left;
}
.SearchBar_Results__11fGs a > span{
    display: block;
    width: 100%;
    text-align: left;
    color: black;
}
.SearchBar_Results__11fGs a > span > span{
    display: block;
    font-weight: 300;
}
.SearchBar_Details__3vrGZ{
    display: block !important;
    font-weight: bold !important;
    font-size: 11px !important;
    color: var(--aic-second-green) !important;
    padding-top: 3px !important;
}
.SearchBar_NoResults__35Awg{
    padding: 10px;
    background: white;
    color: #8dbd40;
    font-weight: 500;
    font-size: 14px;
    border-top: 1px solid #e0e0e0;
}

@media (max-width: 770px){

    .SearchBar_Container__3WFao{
        background: whitesmoke;
        width: calc(100% - 180px);
        margin-right: 21px;
        height: -webkit-fit-content;
        height: -moz-fit-content;
        height: fit-content;
        border: 1px solid #eaeaea;
        transition: width 0.3s;
        border-radius: 3px;
        position: fixed;
        top: 12px;
        right: 100px;
        left: 70px;
        
    }

    .SearchBar_Results__11fGs {
        width: 100vw;
        position: fixed;
        left: 0;
        top: 60px;
        box-shadow: 0px 5px 16px -5px #00000066;
    }
}
.src-components-UI-NavBar-NavBar-module__nav--r3oBn {
  display: flex;
  background-color: var(--aic-green);
  height: 50px;
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  z-index: 500;
  align-items: center;
  justify-content: flex-end;
  color: var(--light-gray);
}
.src-components-UI-NavBar-NavBar-module__logo--2FRqv {
  height: 30px;
  display: block;
  position: fixed;
  left: 13px;
}
.src-components-UI-NavBar-NavBar-module__menuOption--3Y2b5 {
  margin-right: 12px;
}
.src-components-UI-NavBar-NavBar-module__menuOption--3Y2b5 button > svg {
  -webkit-filter: brightness(10);
          filter: brightness(10);
}
.src-components-UI-NavBar-NavBar-module__notifications--2NKkZ {
  top: 4px;
  margin-right: 30px;
  position: relative;
}
.src-components-UI-NavBar-NavBar-module__notifications--2NKkZ a {
  text-decoration: none;
}
.src-components-UI-NavBar-NavBar-module__notifications--2NKkZ b {
  background: #f5222e;
  color: white;
  padding: 1px 4px;
  border-radius: 10px !important;
  position: absolute;
  top: 11px;
  right: 67px;
  font-size: 11px;
  border: 1px solid white;
}
.src-components-UI-NavBar-NavBar-module__notifications--2NKkZ svg {
  -webkit-filter: brightness(10);
          filter: brightness(10);
}
.src-components-UI-NavBar-NavBar-module__ProfileImage--2803T {
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 50%;
  display: block;
}
@media (max-width: 770px) {
  .src-components-UI-NavBar-NavBar-module__nav--r3oBn {
    display: flex;
    background-color: var(--aic-green);
    height: 60px;
    position: fixed;
    top: 0px;
    left: 0px;
    width: 100%;
    z-index: 500;
    align-items: center;
    justify-content: flex-end;
    color: var(--light-gray);
  }
  .src-components-UI-NavBar-NavBar-module__logo--2FRqv {
    height: 34px;
    display: block;
    position: fixed;
    left: 13px;
  }
}
/*
TODO: move to a different component as small-tooltip or something like that? perhaps...
.tooltip {
    position: relative;
}
.tooltip .tooltiptext {
    visibility: hidden;
    width: 30px;
    background-color: #555;
    font-size: 0.5em;
    color: #fff;
    text-align: center;
    padding: 5px;
    border-radius: 3px;
    position: absolute;
    z-index: 1;
    top: 140%;
    margin-left: -30px;
    opacity: 0;
    transition: opacity 0.3s;
}
.tooltip:hover .tooltiptext {
    visibility: visible;
    opacity: 1;
}
*/

.src-components-UI-SideMenu-SideMenu-module__menu--n-aC0 {
  overflow: auto;
  padding: 10px 0px !important;
}
.src-components-UI-SideMenu-SideMenu-module__menu--n-aC0 li {
  text-align: left !important;
}
.src-components-UI-SideMenu-SideMenu-module__menu--n-aC0 > ul {
  height: calc(100vh - 50px);
}
.src-components-UI-SideMenu-SideMenu-module__container--2W8_Q {
  position: relative;
  top: -60px;
}
.src-components-UI-SideMenu-SideMenu-module__container--2W8_Q .ant-menu.ant-menu-light.ant-menu-root.ant-menu-inline {
  overflow: auto;
}
.src-components-UI-SideMenu-SideMenu-module__container--2W8_Q .ant-menu-submenu-title,
.src-components-UI-SideMenu-SideMenu-module__container--2W8_Q .ant-menu-item {
  text-align: left;
  padding-left: 60px;
}
.src-components-UI-SideMenu-SideMenu-module__imgContainer--C_PfQ {
  height: 60px;
}
.src-components-UI-SideMenu-SideMenu-module__imgContainer--C_PfQ .ant-btn {
  left: 18px;
}
.src-components-UI-SideMenu-SideMenu-module__Logo--F8koa {
  height: 60px;
  width: 232px;
  padding: 13px;
  top: 5px;
  position: relative;
}
.src-components-UI-SideMenu-SideMenu-module__AltLogo--1jzF8 {
  position: relative;
  left: 45px;
  height: 50px;
  padding: 10px;
  display: none;
}
.src-components-UI-SideMenu-SideMenu-module__addApp--14S29 {
  font-weight: 500;
}
.src-components-UI-SideMenu-SideMenu-module__drawerMenu--eC7cX {
  position: absolute;
  bottom: 0px;
  left: 0px;
  width: 100%;
  height: calc(100% - 56px);
  overflow: auto;
}
.src-components-UI-SideMenu-SideMenu-module__drawerMenu--eC7cX ul {
  height: 100%;
}
.src-components-UI-SideMenu-SideMenu-module__drawerLogo--3-2uQ {
  width: 170px;
  display: block;
}
.src-components-UI-SideMenu-SideMenu-module__menuIcon--1sZoh {
  position: fixed;
  width: 36px;
  height: 36px;
  display: flex !important;
  align-items: center;
  justify-content: center;
  border-radius: 4px;
  left: 7px;
  top: 7px;
  font-size: 30px;
  visibility: hidden;
  opacity: 0;
}
.src-components-UI-SideMenu-SideMenu-module__menuIcon--1sZoh > * {
  color: white;
}
.src-components-UI-SideMenu-SideMenu-module__sideMenu--1gZsR > ul {
  height: calc(100vh - 50px);
}
@media (max-width: 800px) {
  .src-components-UI-NavBar-NavBar-module__logo--2FRqv {
    display: none;
  }
  .anticon.anticon-menu.src-components-UI-SideMenu-SideMenu-module__menuIcon--1sZoh {
    margin-top: 4px;
  }
  .src-components-UI-SideMenu-SideMenu-module__sideMenu--1gZsR {
    display: none;
  }
  .src-components-UI-SideMenu-SideMenu-module__menuIcon--1sZoh {
    visibility: visible;
    opacity: 1;
  }
  .src-components-UI-SideMenu-SideMenu-module__container--2W8_Q {
    overflow: auto;
    position: fixed;
    left: 0px;
    top: 60px;
    z-index: 1000;
  }
  .src-components-UI-SideMenu-SideMenu-module__imgContainer--C_PfQ {
    position: fixed;
    top: 0px;
    left: 0px;
    z-index: 1000;
  }
  .src-components-UI-SideMenu-SideMenu-module__Logo--F8koa {
    display: none;
  }
  .src-components-UI-SideMenu-SideMenu-module__AltLogo--1jzF8 {
    display: block;
  }
}

.ProfileLayout_Container__tRdOB {
  display: flex;
  height: 100vh;
  widows: 100vw;
  padding-top: 50px;
}

.ProfileLayout_SideMenu__3nWtl {
  height: 100%;
  display: block;
  width: 190px;
}

.ProfileLayout_Content__1YN3y {
  width: calc(100% - 190px);
  height: 100%;
  overflow: auto;
  background: #f5f5f5;
}
.ProfileLayout_Content__1YN3y p {
  margin: 0;
}
@media (max-width: 800px) {
  .ProfileLayout_Content__1YN3y {
    width: 100%;
    padding: 0px 0px 40px;
  }
  .ProfileLayout_SideMenu__3nWtl {
    height: 0vh;
    width: 0vw;
  }
}

.AppForm_linecard__3D76q{
    margin: 20px;
}
.AppForm_container__24FRg{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
}  
.AppForm_NoResults__2hBhs{
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 125px);
    width: 100%;
}
.AppForm_NoResults__2hBhs b{
    color: #c9c9c9;
    font-weight: 400;
}
.AppForm_Pills__1X8FU{
	text-align: left;
    padding: 0px 40px;
	margin-top: -5px;
}
.AppForm_Pills__1X8FU > span{
	display: inline-block;
	width: -webkit-fit-content;
	width: -moz-fit-content;
	width: fit-content;
	padding: 8px 16px;
	border-radius: 0px;
	border: 2px solid #8dbd40;
	color: #8dbd40;
	margin: 10px 5px;
	border-radius: 40px;
	cursor: pointer;
	font-weight: bold;
}
.AppForm_PillSelected__3eruc{
	background: #8dbe40 !important;
	color:white !important;
}
.AppForm_buttonContainer__3XGDz{
    display: flex;
    justify-content: flex-end;
}
.AppForm_preloadercontainer__28qY4 {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
}
.AppForm_NoResults__2hBhs > span{
    display: grid;
    grid-template: repeat(100%, 2fr);
    grid-gap: 20px;
    align-items: center;
    text-align: center;
}
.AppForm_BackArrow__2dZgg{
    position: absolute;
    left: 0px;
    display: block;
    top: 5px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
.AppForm_BackArrow__2dZgg img{
	width: 20px;
	height: auto;
    display: block;
    cursor: pointer;
}

.AppForm_field__uwuoM{
    margin-top: 15px;
}
.FilePreview_Container__1TYHp{
	width: 100%;
	border-radius: 6px;
	background: #f6f6f6;
	padding: 8px;
	display: flex;	
	justify-content: space-between;
	align-items: center;
	height: 40px;
}

.FilePreview_Container__1TYHp > div{
	display: flex;
	align-items: center;
	width: 62px;
	justify-content: space-between;
}

.FilePreview_Container__1TYHp a{
	display: flex;
    align-items: center;
}

.FilePreview_Container__1TYHp svg{
	opacity: 0.9;
}

.FilePreview_label__1i_16{
	margin: 0 !important;
	font-size: 16px;
}

.FilePreview_modified__uVCQy, .FilePreview_modified__uVCQy label{
	background:#fddcc5;
}

.CompanyForm_linecard__p_wIQ{
    margin: 20px;
}
.CompanyForm_container__2Mzei{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
}  
.CompanyForm_NoResults__6t_4H{
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 125px);
    width: 100%;
}
.CompanyForm_NoResults__6t_4H b{
    color: #c9c9c9;
    font-weight: 400;
}
.CompanyForm_buttonContainer__3_pnk{
    display: flex;
    justify-content: flex-end;
}
.CompanyForm_preloadercontainer__3GySa {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
}
.CompanyForm_NoResults__6t_4H > span{
    display: grid;
    grid-template: repeat(100%, 2fr);
    grid-gap: 20px;
    align-items: center;
    text-align: center;
}
.CompanyForm_BackArrow__1vlDr{
    position: absolute;
    left: 0px;
    display: block;
    top: 5px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
.CompanyForm_BackArrow__1vlDr img{
	width: 20px;
	height: auto;
    display: block;
    cursor: pointer;
}
.SecurityForm_linecard__2_cIk{
    margin: 20px;
}
.SecurityForm_container__34dMv{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
}  
.SecurityForm_NoResults__nMhib{
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 125px);
    width: 100%;
}
.SecurityForm_NoResults__nMhib b{
    color: #c9c9c9;
    font-weight: 400;
}
.SecurityForm_buttonContainer__VrxNN{
    display: flex;
    justify-content: flex-end;
}
.SecurityForm_preloadercontainer__3Caie {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
}
.SecurityForm_NoResults__nMhib > span{
    display: grid;
    grid-template: repeat(100%, 2fr);
    grid-gap: 20px;
    align-items: center;
    text-align: center;
}
.SecurityForm_BackArrow__JhXy_{
    position: absolute;
    left: 0px;
    display: block;
    top: 5px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
.SecurityForm_BackArrow__JhXy_ img{
	width: 20px;
	height: auto;
    display: block;
    cursor: pointer;
}
.CopyrightForm_linecard__36ald{
    margin: 20px;
}
.CopyrightForm_container__3zd8B{
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 10px;
}  
.CopyrightForm_NoResults__2j47f{
    display: flex;
    justify-content: center;
    align-items: center;
    height: calc(100% - 125px);
    width: 100%;
}
.CopyrightForm_NoResults__2j47f b{
    color: #c9c9c9;
    font-weight: 400;
}
.CopyrightForm_buttonContainer__pmb5w{
    display: flex;
    justify-content: flex-end;
}
.CopyrightForm_preloadercontainer__UJwZ2 {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
}
.CopyrightForm_NoResults__2j47f > span{
    display: grid;
    grid-template: repeat(100%, 2fr);
    grid-gap: 20px;
    align-items: center;
    text-align: center;
}
.CopyrightForm_BackArrow__2Caa6{
    position: absolute;
    left: 0px;
    display: block;
    top: 5px;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
}
.CopyrightForm_BackArrow__2Caa6 img{
	width: 20px;
	height: auto;
    display: block;
    cursor: pointer;
}
.src-components-Notifications-Notifications-module__container--1LVdB {
  width: 100%;
  height: 100%;
  padding: 30px 20px 30px;
}
.src-components-Notifications-Notifications-module__container--1LVdB .ant-radio-button-wrapper {
  width: 50%;
}
.src-components-Notifications-Notifications-module__filters--2NY8m {
  margin-top: 20px !important;
}
.src-components-Notifications-Notifications-module__managers--1_kXt {
  display: flex !important;
  flex-direction: column !important;
  bottom: 16px;
}
.src-components-Notifications-Notifications-module__managers--1_kXt > b {
  font-size: 11px !important;
  text-align: left;
  color: var(--aic-secondary);
  font-weight: 500;
  margin: 0;
}
.src-components-Notifications-Notifications-module__cards_container--1rqwe {
  display: grid;
  grid-template-rows: 1fr;
  grid-gap: 10px;
  padding-top: 15px;
}
.src-components-Notifications-Notifications-module__BackArrow--6QBCv {
  position: absolute;
  left: 20px;
  display: block;
  top: 25px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
}
.src-components-Notifications-Notifications-module__BackArrow--6QBCv img {
  width: 20px;
  height: auto;
  display: block;
  cursor: pointer;
}
.src-components-Notifications-Notifications-module__NoResults--15viO {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 125px);
  width: 100%;
  margin-bottom: 500px;
}
.src-components-Notifications-Notifications-module__NoResults--15viO b {
  color: #c9c9c9;
  font-weight: 400;
}
.src-components-Notifications-Notifications-module__preloadercontainer--39Kkf {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background: white;
}
.src-components-Notifications-Notifications-module__NoResults--15viO > span {
  display: grid;
  grid-template: repeat(100%, 2fr);
  grid-gap: 20px;
  align-items: center;
  text-align: center;
}
.src-components-Notifications-Notifications-module__notification--2uUuo {
  background: white;
  display: flex;
  justify-content: space-between;
  box-shadow: 0px 0px 13px 0px #0000001a;
  border-radius: 5px;
  width: 100%;
  padding: 10px 15px;
  align-items: center;
}
.src-components-Notifications-Notifications-module__notification--2uUuo p {
  margin: 0;
  text-align: left;
}
.src-components-Notifications-Notifications-module__notification--2uUuo > div {
  display: inline-flex;
  width: 290px;
  justify-content: space-between;
  align-items: center;
}
.src-components-Notifications-Notifications-module__completion--1mvRs {
  width: 200px;
}
.src-components-Notifications-Notifications-module__Tabs--3PbDB {
  border-bottom: 1px solid whitesmoke;
  position: fixed;
  width: 100%;
  background: white;
  z-index: 200;
}
@media (max-width: 576px) {
  .src-components-Notifications-Notifications-module__managers--1_kXt .ant-select,
  .src-components-Notifications-Notifications-module__managers--1_kXt .ant-select.ant-select-enabled {
    width: 100% !important;
  }
}
@media (max-width: 494px) {
  .src-components-Notifications-Notifications-module__notification--2uUuo {
    flex-direction: column;
    padding-bottom: 10px;
  }
  .src-components-Notifications-Notifications-module__notification--2uUuo p {
    margin: 15px;
  }
  .src-components-Notifications-Notifications-module__completion--1mvRs {
    width: 100%;
  }
  .src-components-Notifications-Notifications-module__notification--2uUuo > div {
    margin: 5px;
    justify-content: space-around;
  }
}
@media (max-width: 370px) {
  .src-components-Notifications-Notifications-module__container--1LVdB {
    align-items: center;
    padding: 60px 10px 10px 10px;
  }
  .src-components-Notifications-Notifications-module__notification--2uUuo {
    padding: 5px;
  }
  .src-components-Notifications-Notifications-module__notification--2uUuo p {
    margin: 5px;
  }
  .src-components-Notifications-Notifications-module__completion--1mvRs {
    width: 60%;
  }
}

.ApkDetails_Container__3HoWn{
    width: 100%;
    margin: auto;
    height: 100%;
    display: flex;
    flex-direction: column;
    background: white;
    justify-content: center;
    align-items: center;
}

.ApkDetails_Content__1ZazC {
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    margin: 40px auto;
    width: -webkit-fit-content;
    width: -moz-fit-content;
    width: fit-content;
}

.ApkDetails_file__1Dkos{
    display: flex;
    justify-content: center;
    align-items: center;
    margin: auto;
    min-width: 300px;
    width: 100%;
    height: 80px;
    border: 4px dashed gainsboro;
}
.ApkDetails_dragging__1nH_h{
    font-weight: bold;
}
.ApkDetails_details__1idmv{
    padding: 10px;
    border: 2px solid #7dc11a;
    border-radius: 5px;
    margin: 40px 0px 0px;
    display: flex;
    align-items: center;
}
.ApkDetails_details__1idmv img{
    margin: 10px;
}
.ApkDetails_details__1idmv tr td:first-child{
    font-weight: bold;
}
.ApkDetails_details__1idmv td{
    text-align: left;
}
.src-components-Apps-Apps-module__grid--2RphB {
  position: relative;
  width: calc(100% - 20px);
  margin: auto;
  display: block;
  height: 100%;
}
.src-components-Apps-Apps-module__removedFilter--4Sc78 .AdvancedFilter_SearchContainer__2IK0I {
  width: calc(100% - 100px) !important;
}
.src-components-Apps-Apps-module__removedFilter--4Sc78 .AdvancedFilter_filterButtons__3JV4I {
  width: 100px !important;
}
.src-components-Apps-Apps-module__Header--2cjL5 {
  padding: 20px;
  text-align: left;
}
.src-components-Apps-Apps-module__Header--2cjL5 h3 {
  margin-bottom: 10px;
}
.src-components-Apps-Apps-module__AppsGrid--3Po53 {
  position: relative;
  width: 100%;
  padding: 20px;
  text-align: center;
  display: grid;
  grid-template-columns: repeat(4, calc(25% - 18px));
  grid-gap: 25px;
}
.src-components-Apps-Apps-module__Filter--1f8w_ {
  background: white;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  /*border-radius: 50px;*/
  box-shadow: 0px 0px 14px 0px #0000000d;
  border: 1px solid #f1f1f1;
}
.src-components-Apps-Apps-module__Filter--1f8w_ svg {
  -webkit-filter: brightness(2.5);
          filter: brightness(2.5);
}
.src-components-Apps-Apps-module__Container--2x5cf {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}
.src-components-Apps-Apps-module__DropOptions--11Ud7 {
  padding: 0px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 10px auto;
}
.src-components-Apps-Apps-module__filtercontainer--1Wn05 {
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  grid-gap: 10px;
  margin-top: 20px;
}
.src-components-Apps-Apps-module__advancedsearch--3muFS {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 10px;
  margin-top: 20px;
}
.src-components-Apps-Apps-module__advancedsearch--3muFS b {
  font-size: 11px !important;
  text-align: left;
  color: var(--aic-secondary);
  font-weight: 500;
  margin: 0;
}
.src-components-Apps-Apps-module__filtercontainer--1Wn05 > span > b {
  margin-bottom: 3px;
  display: block;
}
.src-components-Apps-Apps-module__SearchContainer--3shZD {
  border-radius: 0px;
  overflow: hidden;
  width: calc(100% - 200px);
}
.src-components-Apps-Apps-module__RadioContainer--36KoY {
  display: flex;
  flex-flow: column;
  height: 80px;
  justify-content: space-between;
}
.src-components-Apps-Apps-module__Flex--oAe7N {
  display: flex;
  align-items: center;
}
.src-components-Apps-Apps-module__AL--3MDpK {
  text-align: left;
  display: block;
}
.src-components-Apps-Apps-module__NoResults--3kA8t {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 125px);
  width: 100%;
}
.src-components-Apps-Apps-module__NoResults--3kA8t span {
  color: #c9c9c9;
}
.src-components-Apps-Apps-module__Tabs--2ofC7 {
  border-bottom: 1px solid whitesmoke;
  position: fixed;
  width: 100%;
  background: white;
  z-index: 200;
}
.src-components-Apps-Apps-module__invisible--3ThaD {
  display: none;
}
.src-components-Apps-Apps-module__switch--1JbBD {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.src-components-Apps-Apps-module__label--M8ZdQ {
  margin: 0px !important;
  font-size: 11px !important;
  text-align: center;
  color: var(--aic-secondary);
  font-weight: 500;
}
.src-components-Apps-Apps-module__advanced--mG-vD {
  cursor: pointer;
  margin: 5px 0px 10px !important;
}
@media (max-width: 1300px) {
  .src-components-Apps-Apps-module__AppsGrid--3Po53 {
    grid-template-columns: repeat(3, calc(33% - 14px));
  }
}
@media (max-width: 1100px) {
  .src-components-Apps-Apps-module__AppsGrid--3Po53 {
    padding: 5px;
    grid-template-columns: repeat(2, calc(50% - 12px));
  }
}
@media (max-width: 770px) {
  .src-components-Apps-Apps-module__Filter--1f8w_ {
    width: 35px;
    height: 35px;
  }
  .src-components-Apps-Apps-module__AppsGrid--3Po53 {
    margin-bottom: 50px;
  }
}
@media (max-width: 650px) {
  .src-components-Apps-Apps-module__filtercontainer--1Wn05 {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 500px) {
  .src-components-Apps-Apps-module__Tabs--2ofC7 > label {
    font-size: 0.775em;
  }
  .src-components-Apps-Apps-module__AppsGrid--3Po53 {
    grid-template-columns: repeat(1, calc(100% - 30px));
    justify-content: center;
  }
  .src-components-Apps-Apps-module__Filter--1f8w_ {
    width: calc(100% - 120px);
  }
  .src-components-Apps-Apps-module__filtercontainer--1Wn05 > span > b {
    font-size: small;
  }
}
@media screen and (max-width: 460px) {
  .src-components-Apps-Apps-module__removedFilter--4Sc78 .AdvancedFilter_SearchContainer__2IK0I {
    width: 100% !important;
  }
  .src-components-Apps-Apps-module__removedFilter--4Sc78 .AdvancedFilter_filterButtons__3JV4I {
    width: 200px !important;
  }
}

.AppCard_Card__3OJDJ {
    display: block;
    text-decoration: none;
    background: white;
    border-radius: 4px;
    box-shadow: 0px 0px 13px 0px #0000001a;
    transform: scale(1);
    transition: all 0.2s;
    padding-bottom: 10px;
}

.AppCard_Card__3OJDJ:hover{
    transform: scale(1.03);
    box-shadow: 0px 0px 18px 0px #00000022;
}

.AppCard_info__2wBJx {
    display: flex;
    align-items: center;
}
.AppCard_info__2wBJx div span{
    color: #2f2f2f;
}
.AppCard_dist_details__34-pt{
    margin: 2px 12px 0px;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
    border-top: 1px solid #dedede;
    padding-top: 7px;
}
.AppCard_dist_details__34-pt span{
    display: inline-flex;
    background: gray;
    color: white;
    margin: 5px 0px 5px 5px;
    padding: 3px 6px;
    font-size: 11px;
    font-weight: 700;
    border-radius: 3px;
    text-align: center;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 40px;
    justify-content: center;
    align-items: center;
}
.AppCard_dist_details__34-pt svg{
    -webkit-filter: brightness(10);
            filter: brightness(10);
}

.AppCard_img_container__2rgiF {
    width: 65px;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
}

.AppCard_img_container__2rgiF + div{
    width: calc(100% - 60px);
    text-align: left;
}

.AppCard_app_image__3mZ7e {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    align-self: center;
    justify-self: center;
    border: 1px solid #e6e6e6;
}
.AdvancedFilter_Container__1jK_T {
  display: flex;
  /* align-items: flex-end; */
  justify-content: space-between;
}
.AdvancedFilter_SearchContainer__1xUGR {
  margin-bottom: 20px;
  width: calc(100% - 200px);
}
.AdvancedFilter_filtercontainer__2Gcwi {
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  grid-gap: 10px;
  margin-top: 20px;
}
.AdvancedFilter_filterButtons__1ehL3 {
  margin-left: 20px;
  width: 200px;
}

.AdvancedFilter_advancedsearch__1t-EV {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  margin-top: 20px;
}
.AdvancedFilter_advancedsearch__1t-EV b {
  font-size: 11px !important;
  text-align: left;
  color: var(--aic-secondary);
  font-weight: 500;
  margin: 0;
}

.AdvancedFilter_filtercontainer__2Gcwi > span > b {
  margin-bottom: 3px;
  display: block;
}

.AdvancedFilter_advanced__hS3et {
  cursor: pointer;
  margin: 5px 0px 10px !important;
}

@media (max-width: 650px) {
  .AdvancedFilter_filtercontainer__2Gcwi {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 500px) {
  .AdvancedFilter_filtercontainer__2Gcwi > span > b {
    font-size: small;
  }
}
@media screen and (max-width: 460px) {
  .AdvancedFilter_SearchContainer__1xUGR {
    width: 100% !important;
  }
  .AdvancedFilter_Container__1jK_T {
    flex-direction: column;
  }
}

.AppsPending_grid__1hFz1 {
  position: relative;
  width: calc(100% - 20px);
  margin: auto;
  display: block;
  height: 100%;
}

.AppsPending_Header__1EK7U {
  padding: 20px;
}

.AppsPending_Header__1EK7U h3 {
  margin-bottom: 10px;
  text-align: left;
}

.AppsPending_AppsGrid__3v4yT {
  position: relative;
  width: 100%;
  padding: 25px;
  text-align: center;
  grid-template-columns: repeat(3, calc(33% - 13px));
  grid-gap: 25px;
  display: grid;
}

.AppsPending_Filter__3K6cX {
  background: white;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  box-shadow: 0px 0px 14px 0px #0000000d;
  border: 1px solid #f1f1f1;
}

.AppsPending_Filter__3K6cX svg {
  -webkit-filter: brightness(2.5);
          filter: brightness(2.5);
}

.AppsPending_Container__1cTPr {
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.AppsPending_DropOptions__2lI5_ {
  padding: 0px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 10px auto;
}

.AppsPending_SearchContainer__2A05w {
  overflow: hidden;
  width: calc(100% - 60px);
}

.AppsPending_RadioContainer__3hE-N {
  display: flex;
  flex-flow: column;
  height: 50px;
  justify-content: space-between;
}

.AppsPending_Flex__3CGGR {
  display: flex;
  align-items: center;
}

.AppsPending_AL__5XfA4 {
  text-align: left;
  display: block;
}
.AppsPending_NoResults__S7qEd {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 125px);
  width: 100%;
}
.AppsPending_NoResults__S7qEd span {
  color: #c9c9c9;
}
.AppsPending_Tabs__33c9M {
  border-bottom: 1px solid whitesmoke;
  position: fixed;
  width: 100%;
  background: white;
  z-index: 200;
}

@media (max-width: 1300px) {
  .AppsPending_AppsGrid__3v4yT {
    grid-template-columns: repeat(3, calc(33% - 14px));
  }
}
@media (max-width: 1100px) {
  .AppsPending_AppsGrid__3v4yT {
    grid-template-columns: repeat(2, calc(50% - 12px));
  }
}
@media screen and (max-width: 500px) {
  .AppsPending_Tabs__33c9M {
    width: 90% !important;
  }
  .AppsPending_Tabs__33c9M > label {
    font-size: 0.775em;
  }
  .AppsPending_AppsGrid__3v4yT {
    grid-template-columns: repeat(1, calc(100% - 30px));
    justify-content: center;
  }
  .AppsPending_Filter__3K6cX {
    width: calc(100% - 120px);
  }
  .AppsPending_filtercontainer__314n0 > span > b {
    font-size: small;
  }
}

@media (max-width: 770px) {
  .AppsPending_Filter__3K6cX {
    width: 35px;
    height: 35px;
  }
  .AppsPending_AppsGrid__3v4yT {
    padding: 5px;
    margin-bottom: 50px;
  }
}
@media (max-width: 650px) {
  .AppsPending_filtercontainer__314n0 {
    grid-template-columns: 1fr;
  }
}

.AppPendingCard_Card__nKa9R {
    display: block;
    text-decoration: none;
    background: white;
    border-radius: 4px;
    box-shadow: 0px 0px 13px 0px #0000001a;
    transition: all 0.2s;
    padding-bottom: 10px;
}
.AppPendingCard_Card__nKa9R:hover{
    box-shadow: 0px 0px 18px 0px #00000022;
}
.AppPendingCard_info__CTMz9 {
    display: flex;
    align-items: center;
}
.AppPendingCard_dist_details__3FReV{
    margin: 2px 12px 0px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    border-top: 1px solid #dedede;
    padding-top: 7px;
}
.AppPendingCard_flex__2pUJH{
    display: inline-flex;
    margin-right: 5px;
    color: white;
    padding: 3px 6px;
    font-size: 12px;
    font-weight: 500;
    border-radius: 3px;
    text-align: center;
    height: -webkit-fit-content;
    height: -moz-fit-content;
    height: fit-content;
    min-width: 40px;
    justify-content: center;
    align-items: center;
}
.AppPendingCard_img_container__1SY2S {
    width: 65px;
    height: 65px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.AppPendingCard_img_container__1SY2S + div{
    width: calc(100% - 60px);
    text-align: left;
}
.AppPendingCard_app_image__V2E2Y {
    width: 45px;
    height: 45px;
    border-radius: 50%;
    align-self: center;
    justify-self: center;
    border: 1px solid #e6e6e6;
}
.AppPendingCard_clientsList__1mA96{
    display: grid;
    grid-template-columns: repeat(1, 1fr);
    grid-gap: 10px;
}
.AppPendingCard_controls__1GirX{
    display: flex;
    width: 160px;
    height: 30px;
    justify-content: space-between
}
.src-components-Sales-Renewals-module__grid--1ALj0 {
  position: relative;
  width: calc(100% - 20px);
  margin: auto;
  display: block;
  height: 100%;
}
.src-components-Sales-Renewals-module__Header--m49Z2 {
  padding: 20px;
  text-align: left;
}
.src-components-Sales-Renewals-module__Header--m49Z2 h3 {
  margin-bottom: 10px;
}
.src-components-Sales-Renewals-module__filterButtons--3w96r {
  margin-left: 20px;
  width: 200px;
}
.src-components-Sales-Renewals-module__AppsGrid--18sNq {
  position: relative;
  width: 100%;
  padding: 20px;
  text-align: center;
  display: grid;
  grid-template-columns: repeat(4, calc(25% - 18px));
  grid-gap: 25px;
}
.src-components-Sales-Renewals-module__Filter--27ibt {
  background: white;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  /*border-radius: 50px;*/
  box-shadow: 0px 0px 14px 0px #0000000d;
  border: 1px solid #f1f1f1;
}
.src-components-Sales-Renewals-module__Filter--27ibt svg {
  -webkit-filter: brightness(2.5);
          filter: brightness(2.5);
}
.src-components-Sales-Renewals-module__Container--5HcqP {
  display: flex;
  justify-content: space-between;
}
.src-components-Sales-Renewals-module__DropOptions--2oVxi {
  padding: 0px;
  width: -webkit-fit-content;
  width: -moz-fit-content;
  width: fit-content;
  margin: 10px auto;
}
.src-components-Sales-Renewals-module__filtercontainer--3P1FY {
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  grid-gap: 10px;
  margin-top: 20px;
}
.src-components-Sales-Renewals-module__advancedsearch--S0yZ3 {
  display: grid;
  grid-template-columns: repeat(6, 1fr);
  grid-gap: 10px;
  margin-top: 20px;
}
.src-components-Sales-Renewals-module__advancedsearch--S0yZ3 b {
  font-size: 11px !important;
  text-align: left;
  color: var(--aic-secondary);
  font-weight: 500;
  margin: 0;
}
.src-components-Sales-Renewals-module__filtercontainer--3P1FY > span > b {
  margin-bottom: 3px;
  display: block;
}
.src-components-Sales-Renewals-module__SearchContainer--pfEjG {
  margin-bottom: 20px;
  width: calc(100% - 200px);
}
.src-components-Sales-Renewals-module__Buttons--1iFSA {
  width: 30%;
}
.src-components-Sales-Renewals-module__RadioContainer--Dhfdv {
  display: flex;
  flex-flow: column;
  height: 80px;
  justify-content: space-between;
}
.src-components-Sales-Renewals-module__Flex--1-RON {
  display: flex;
  align-items: center;
}
.src-components-Sales-Renewals-module__AL--3DWHM {
  text-align: left;
  display: block;
}
.src-components-Sales-Renewals-module__NoResults--3MMFm {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 125px);
  width: 100%;
}
.src-components-Sales-Renewals-module__NoResults--3MMFm span {
  color: #c9c9c9;
}
.src-components-Sales-Renewals-module__Tabs--2JJKg {
  border-bottom: 1px solid whitesmoke;
  position: fixed;
  width: 100%;
  background: white;
  z-index: 200;
}
.src-components-Sales-Renewals-module__invisible--1g5Cl {
  display: none;
}
.src-components-Sales-Renewals-module__switch--2FxgP {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}
.src-components-Sales-Renewals-module__label--YsH8Z {
  margin: 0px !important;
  font-size: 11px !important;
  text-align: center;
  color: var(--aic-secondary);
  font-weight: 500;
}
.src-components-Sales-Renewals-module__advanced--1XnIx {
  cursor: pointer;
  margin: 5px 0px 10px !important;
}
@media (max-width: 1300px) {
  .src-components-Sales-Renewals-module__AppsGrid--18sNq {
    grid-template-columns: repeat(3, calc(33% - 14px));
  }
}
@media (max-width: 1100px) {
  .src-components-Sales-Renewals-module__AppsGrid--18sNq {
    padding: 5px;
    grid-template-columns: repeat(2, calc(50% - 12px));
  }
}
@media screen and (max-width: 800px) {
  .src-components-Sales-Renewals-module__Buttons--1iFSA {
    width: 40%;
  }
}
@media (max-width: 770px) {
  .src-components-Sales-Renewals-module__Filter--27ibt {
    width: 35px;
    height: 35px;
  }
  .src-components-Sales-Renewals-module__AppsGrid--18sNq {
    margin-bottom: 50px;
  }
}
@media (max-width: 650px) {
  .src-components-Sales-Renewals-module__filtercontainer--3P1FY {
    grid-template-columns: 1fr;
  }
}
@media screen and (max-width: 500px) {
  .src-components-Sales-Renewals-module__Tabs--2JJKg > label {
    font-size: 0.775em;
  }
  .src-components-Sales-Renewals-module__AppsGrid--18sNq {
    grid-template-columns: repeat(1, calc(100% - 30px));
    justify-content: center;
  }
  .src-components-Sales-Renewals-module__Filter--27ibt {
    width: calc(100% - 120px);
  }
  .src-components-Sales-Renewals-module__filtercontainer--3P1FY > span > b {
    font-size: small;
  }
}
@media screen and (max-width: 460px) {
  .src-components-Sales-Renewals-module__SearchContainer--pfEjG {
    width: 100%;
  }
  .src-components-Sales-Renewals-module__Container--5HcqP {
    flex-direction: column;
  }
}

.src-components-SOP-Editor-Editor-module__container--2CD0_ {
  height: 100%;
  position: relative;
}
.src-components-SOP-Editor-Editor-module__container--2CD0_ .rdw-editor-wrapper {
  height: calc(100% - 52px);
}
.src-components-SOP-Editor-Editor-module__container--2CD0_ .rdw-editor-main {
  max-height: unset !important;
}
.src-components-SOP-Editor-Editor-module__container--2CD0_ .rdw-image-imagewrapper,
.src-components-SOP-Editor-Editor-module__container--2CD0_ .public-DraftEditor-content {
  width: 100% !important;
  position: relative;
}
.src-components-SOP-Editor-Editor-module__container--2CD0_ .rdw-image-imagewrapper img,
.src-components-SOP-Editor-Editor-module__container--2CD0_ .public-DraftEditor-content img {
  max-width: 100% !important;
  position: relative;
  display: block;
  margin: auto;
}
.src-components-SOP-Editor-Editor-module__actions--2qNtL {
  position: relative;
  margin: 10px;
}
.src-components-SOP-Editor-Editor-module__actions--2qNtL .ant-btn {
  margin-right: 10px;
}
.src-components-SOP-Editor-Editor-module__actions--2qNtL .ant-select {
  margin-right: 10px;
}
.src-components-SOP-Editor-Editor-module__intro--26Bxj {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.src-components-SOP-Editor-Editor-module__attachments--3acUk {
  text-align: left;
  padding: 12px;
}
.src-components-SOP-Editor-Editor-module__attachments--3acUk > div {
  max-width: 200px;
  display: inline-block;
  margin: 8px;
  width: 100%;
  float: left;
}

.src-components-SOP-sop-module__container--2TKMr {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  position: relative;
}
.src-components-SOP-sop-module__container--2TKMr .ant-menu-inline {
  border: none;
}
.src-components-SOP-sop-module__container--2TKMr .ant-input-search {
  width: calc(100% - 16px);
  margin: 0px 8px !important;
}
.src-components-SOP-sop-module__container--2TKMr .ant-tree-node-content-wrapper {
  height: auto !important;
}
.src-components-SOP-sop-module__container--2TKMr .ant-row-flex-space-between span,
.src-components-SOP-sop-module__container--2TKMr .ant-row-flex-space-between {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  white-space: break-spaces;
}
.src-components-SOP-sop-module__advancedSearch--24ycV {
  width: calc(100% - 16px);
  margin-top: 10px;
}
.src-components-SOP-sop-module__menuContainer--1v6Rx {
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
  max-width: 400px;
  min-width: 240px;
}
.src-components-SOP-sop-module__buttonActions--1WRgX {
  visibility: hidden;
}
.src-components-SOP-sop-module__actionsHover--1eaXH:hover .src-components-SOP-sop-module__buttonActions--1WRgX {
  visibility: visible;
}
.src-components-SOP-sop-module__menu--yYJhB {
  padding-top: 15px;
  background: white;
  height: calc(100vh - 145px);
  width: 100%;
  position: relative;
  border-right: 1px solid #e8e8e8;
  overflow: auto;
  overflow-x: scroll;
}
.src-components-SOP-sop-module__menu--yYJhB li {
  text-align: left;
}
.src-components-SOP-sop-module__menu--yYJhB .ant-tree {
  max-height: 880px;
  overflow-y: scroll;
}
.src-components-SOP-sop-module__menu--yYJhB .ant-tree-child-tree.ant-tree-child-tree-open {
  overflow-x: scroll;
}
.src-components-SOP-sop-module__menu--yYJhB .ant-tree-node-content-wrapper .nt-tree-node-content-wrapper-close {
  background: antiquewhite;
  height: auto;
  padding: 10px 0 10px;
}
.src-components-SOP-sop-module__categoryActions--1L6aZ {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
}
.src-components-SOP-sop-module__categoryActions--1L6aZ button {
  margin-left: 8px;
}
.src-components-SOP-sop-module__buttons--atFSP {
  background-color: white;
  display: flex;
  justify-content: space-between;
  border-right: 1px solid #e8e8e8;
  padding: 4px;
  left: 0px;
  bottom: 0px;
  width: 100%;
}
.src-components-SOP-sop-module__buttons--atFSP button {
  margin: 4px;
}
.src-components-SOP-sop-module__content--3cKfy {
  padding-top: 10px;
  width: 100%;
  overflow-y: auto;
  height: 100%;
  background: white;
}
.src-components-SOP-sop-module__content--3cKfy .rdw-editor-wrapper {
  padding: 20px;
}
.src-components-SOP-sop-module__flex--3WFnN {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
@media (max-width: 1180px) {
  .src-components-SOP-sop-module__content--3cKfy .ant-input,
  .src-components-SOP-sop-module__content--3cKfy .ant-select {
    width: 100% !important;
    margin-left: 10px;
  }
}
@media (max-width: 700px) {
  .src-components-SOP-sop-module__container--2TKMr {
    flex-direction: column;
    width: 100%;
  }
  .src-components-SOP-sop-module__content--3cKfy {
    width: 100%;
  }
  .src-components-SOP-sop-module__menu--yYJhB {
    margin-top: 10px;
    width: 100%;
    max-width: unset !important;
    height: 350px;
    overflow-y: scroll;
    left: 0px;
    border-right: 0em;
  }
  .src-components-SOP-sop-module__menuContainer--1v6Rx {
    width: 100%;
    max-width: unset !important;
  }
}

.src-components-SOP-AdvancedSearchModal-Results-Results-module__description--3PGtv {
  word-break: break-all;
}
.src-components-SOP-AdvancedSearchModal-Results-Results-module__card--3IR4r {
  padding: 10px;
  box-shadow: 0px 0px 10px 0px #80808038;
  cursor: pointer;
  border: 1px solid #dddddd;
  border-radius: 3px;
}

.src-components-SOP-AdvancedSearchModal-AdvancedSearchModal-module__searchValue--2Fn0q {
  font-style: italic;
  color: #97c549;
}

.src-components-Help-Editor-Editor-module__container--PUbDE {
  height: 100%;
  position: relative;
}
.src-components-Help-Editor-Editor-module__container--PUbDE .rdw-editor-wrapper {
  height: calc(100% - 52px);
}
.src-components-Help-Editor-Editor-module__container--PUbDE .rdw-editor-main {
  max-height: unset !important;
}
.src-components-Help-Editor-Editor-module__container--PUbDE .rdw-image-imagewrapper {
  width: 100% !important;
  position: relative;
}
.src-components-Help-Editor-Editor-module__container--PUbDE .rdw-image-imagewrapper img {
  max-width: 100% !important;
  position: relative;
  display: block;
  margin: auto;
}
.src-components-Help-Editor-Editor-module__actions--17wax {
  position: relative;
  margin: 10px;
}
.src-components-Help-Editor-Editor-module__actions--17wax .ant-btn {
  margin-right: 10px;
}
.src-components-Help-Editor-Editor-module__actions--17wax .ant-select {
  margin-right: 10px;
}
.src-components-Help-Editor-Editor-module__intro--iUy8E {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.src-components-Help-Editor-Editor-module__attachments--3Fn3g {
  text-align: left;
  padding: 12px;
}
.src-components-Help-Editor-Editor-module__attachments--3Fn3g > div {
  max-width: 200px;
  display: inline-block;
  margin: 8px;
  width: 100%;
  float: left;
}

.src-components-Help-help-module__container--2W1fI {
  display: flex;
  justify-content: center;
  align-items: flex-start;
  height: 100%;
  position: relative;
}
.src-components-Help-help-module__container--2W1fI .ant-menu-inline {
  border: none;
}
.src-components-Help-help-module__container--2W1fI .ant-input-search {
  width: calc(100% - 16px);
  margin: 0px 8px !important;
}
.src-components-Help-help-module__container--2W1fI .ant-tree-node-content-wrapper {
  height: auto !important;
}
.src-components-Help-help-module__container--2W1fI .ant-row-flex-space-between span,
.src-components-Help-help-module__container--2W1fI .ant-row-flex-space-between {
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  white-space: break-spaces;
}
.src-components-Help-help-module__advancedSearch--3KoyI {
  width: calc(100% - 16px);
  margin-top: 10px;
}
.src-components-Help-help-module__menuContainer--3v7NU {
  background: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60%;
  max-width: 400px;
  min-width: 240px;
}
.src-components-Help-help-module__buttonActions--3BTjV {
  visibility: hidden;
}
.src-components-Help-help-module__actionsHover--enEmI:hover .src-components-Help-help-module__buttonActions--3BTjV {
  visibility: visible;
}
.src-components-Help-help-module__menu--2Gyt5 {
  padding-top: 15px;
  background: white;
  height: calc(100vh - 145px);
  width: 100%;
  position: relative;
  border-right: 1px solid #e8e8e8;
  overflow: auto;
  overflow-x: scroll;
}
.src-components-Help-help-module__menu--2Gyt5 li {
  text-align: left;
}
.src-components-Help-help-module__menu--2Gyt5 .ant-tree-child-tree.ant-tree-child-tree-open {
  overflow-x: scroll;
}
.src-components-Help-help-module__menu--2Gyt5 .ant-tree-node-content-wrapper .nt-tree-node-content-wrapper-close {
  background: antiquewhite;
  height: auto;
  padding: 10px 0 10px;
}
.src-components-Help-help-module__categoryActions--1uuvA {
  width: 100%;
  display: flex;
  justify-content: space-between;
  padding-bottom: 30px;
}
.src-components-Help-help-module__categoryActions--1uuvA button {
  margin-left: 8px;
}
.src-components-Help-help-module__buttons--1xkND {
  background-color: white;
  display: flex;
  justify-content: space-between;
  border-right: 1px solid #e8e8e8;
  padding: 4px;
  left: 0px;
  bottom: 0px;
  width: 100%;
}
.src-components-Help-help-module__buttons--1xkND button {
  margin: 4px;
}
.src-components-Help-help-module__content--u9ucJ {
  padding-top: 10px;
  width: 100%;
  overflow-y: auto;
  height: 100%;
  background: white;
}
.src-components-Help-help-module__content--u9ucJ .rdw-editor-wrapper {
  padding: 20px;
}
.src-components-Help-help-module__flex--2CFzP {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
@media (max-width: 700px) {
  .src-components-Help-help-module__container--2W1fI {
    flex-direction: column;
    width: 100%;
  }
  .src-components-Help-help-module__content--u9ucJ {
    width: 100%;
  }
  .src-components-Help-help-module__menu--2Gyt5 {
    margin-top: 10px;
    width: 100%;
    height: 350px;
    overflow-y: scroll;
    left: 0px;
    border-right: 0em;
  }
  .src-components-Help-help-module__menuContainer--3v7NU {
    width: 100%;
  }
}
@media (max-width: 1180px) {
  .src-components-Help-help-module__content--u9ucJ .ant-input,
  .src-components-Help-help-module__content--u9ucJ .ant-select {
    width: 100% !important;
    margin-left: 10px;
  }
}

.src-components-Help-AdvancedSearchModal-Results-Results-module__description--1UF35 {
  word-break: break-all;
}
.src-components-Help-AdvancedSearchModal-Results-Results-module__card--3dmTZ {
  padding: 10px;
  box-shadow: 0px 0px 10px 0px #80808038;
  cursor: pointer;
  border: 1px solid #dddddd;
  border-radius: 3px;
}

.src-components-Help-AdvancedSearchModal-AdvancedSearchModal-module__searchValue--enl7s {
  font-style: italic;
  color: #97c549;
}

.src-components-Documentation-Documentation-module__container--2W1Y0 .ant-tabs-bar.ant-tabs-top-bar {
  margin: 0px;
}

.src-components-SdksSection-SdksModal-module__suggestions--2TZja {
  padding-bottom: 20px;
}
.src-components-SdksSection-SdksModal-module__suggestions--2TZja button {
  margin: 0px 0px 3px;
  height: auto;
  width: 100%;
}
.src-components-SdksSection-SdksModal-module__suggestions--2TZja button p {
  margin: 0px;
  top: 2px;
  position: relative;
}
.src-components-SdksSection-SdksModal-module__suggestions--2TZja button span {
  font-size: 12px !important;
  font-weight: 400;
}

.src-components-SdksSection-SdksSection-module__container--2HFcR {
  position: relative;
}
.src-components-SdksSection-SdksSection-module__container--2HFcR > div:not(.src-components-SdksSection-SdksSection-module__buttons--2VRWD) {
  padding: 20px 20px 0px;
}
.src-components-SdksSection-SdksSection-module__container--2HFcR .ant-table-content {
  background: white;
}
.src-components-SdksSection-SdksSection-module__input--3G3IC {
  width: calc(100% - 510px);
}
.src-components-SdksSection-SdksSection-module__buttons--2VRWD {
  display: flex;
  justify-content: flex-end;
}
.src-components-SdksSection-SdksSection-module__buttons--2VRWD > * {
  margin: 0px 0px 0px 10px;
}
@media (max-width: 767px) {
  .src-components-SdksSection-SdksSection-module__buttons--2VRWD button {
    width: 100%;
    margin-left: 0px;
    margin-top: 10px;
  }
}

.src-components-Mailing-Editor-Editor-module__container--3XEZu .DraftEditor-editorContainer {
  height: calc(100vh - 300px);
}
.src-components-Mailing-Editor-Editor-module__container--3XEZu .rdw-editor-main {
  overflow: auto;
  box-sizing: border-box;
  max-height: 400px;
}
.src-components-Mailing-Editor-Editor-module__container--3XEZu .ant-row {
  text-align: left;
}
.src-components-Mailing-Editor-Editor-module__container--3XEZu > b {
  float: left;
}
.src-components-Mailing-Editor-Editor-module__editor--33ZWa {
  overflow-y: scroll;
  border: 1px solid #d9d9d9;
  border-top-width: 1.02px;
  border-radius: 4px;
  margin-top: 8px;
}
.src-components-Mailing-Editor-Editor-module__flex--1e2-h {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 20px 0px;
}
@media (max-width: 590px) {
  .src-components-Mailing-Editor-Editor-module__flex--1e2-h {
    display: grid;
    grid-gap: 10px;
    grid-template-columns: 1fr;
  }
  .src-components-Mailing-Editor-Editor-module__flex--1e2-h button {
    width: 100%;
  }
}

.src-components-Mailing-Mailing-module__container--1CwSm {
  padding: 20px 20px 0px;
  background: white;
  min-height: 100%;
}
.src-components-Mailing-Mailing-module__container--1CwSm .ant-steps,
.src-components-Mailing-Mailing-module__container--1CwSm .ant-steps-horizontal,
.src-components-Mailing-Mailing-module__container--1CwSm .ant-steps-small,
.src-components-Mailing-Mailing-module__container--1CwSm .ant-steps-label-horizontal {
  text-align: justify;
}
.src-components-Mailing-Mailing-module__container--1CwSm .ant-form-item {
  margin: 0px !important;
}
.src-components-Mailing-Mailing-module__labelModal--2L-3V {
  font-size: 13px !important;
  text-align: left;
  color: var(--aic-secondary);
  font-weight: 500;
  margin: 0;
  padding: 16px 0px 8px;
  display: block;
}
.src-components-Mailing-Mailing-module__result--39_Ka {
  padding-top: 20px;
}

.src-components-Tickets-Kefus-kefus-module__container--1ZAPt {
  display: flex;
  height: 100%;
}
.src-components-Tickets-Kefus-kefus-module__container--1ZAPt .ant-form-item {
  margin-bottom: 8px;
}
.src-components-Tickets-Kefus-kefus-module__container--1ZAPt .ant-form-item label {
  font-size: 13px;
  color: var(--aic-secondary);
  font-weight: 500;
}
.src-components-Tickets-Kefus-kefus-module__container--1ZAPt .ant-form-item .ant-form-item-label {
  padding: 0 !important;
}
.src-components-Tickets-Kefus-kefus-module__container--1ZAPt .ant-form-explain {
  font-size: 12px;
}
.src-components-Tickets-Kefus-kefus-module__details--2uHXJ {
  width: 100%;
  padding: 20px;
  height: calc(100vh - 120px);
  overflow-y: scroll;
}
.src-components-Tickets-Kefus-kefus-module__buttons--3E-sS {
  display: flex;
  justify-content: flex-end;
}
.src-components-Tickets-Kefus-kefus-module__text--1dA0A {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 220px);
}

.src-components-Tickets-Kefus-KefuDetails-KefuDetails-module__title--2BlTD {
  color: var(--aic-secondary);
  margin: 24px 0px 10px 0px !important;
  font-size: 16px;
  text-align: left;
}

.src-components-Tickets-Kefus-KefuDetails-Accounts-Accounts-module__Cards--3Zmz4 {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(400px, 1fr));
  grid-gap: 10px;
  overflow-y: scroll;
}
.src-components-Tickets-Kefus-KefuDetails-Accounts-Accounts-module__table--5FYEY {
  border: 1px solid gainsboro;
  border-radius: 5px;
}

.src-components-Tickets-CardAccount-CardAccount-module__container--36qhZ {
  background: white;
  padding: 15px;
}
.src-components-Tickets-CardAccount-CardAccount-module__container--36qhZ .ant-row,
.src-components-Tickets-CardAccount-CardAccount-module__container--36qhZ .ant-form-item {
  margin: 0 0 12px !important;
}
.src-components-Tickets-CardAccount-CardAccount-module__container--36qhZ .ant-form-item label {
  color: var(--aic-secondary);
}
.src-components-Tickets-CardAccount-CardAccount-module__delete--36Y_6 {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.src-components-Tickets-CardAccount-CardAccount-module__switchContainer--3TZOw .ant-form-item {
  padding: 0 !important;
  margin: 0 !important;
}

.src-components-Tickets-AddWechatAccountModal-AddWechatAccountModal-module__button--mUTcv {
  right: 20px;
  position: fixed!important;
  bottom: 20px;
  z-index: 10;
}


.src-components-Tickets-WechatAccount-WechatAccount-module__container--3elY6 {
  display: flex;
  height: 100%;
}
.src-components-Tickets-WechatAccount-WechatAccount-module__container--3elY6 .ant-form-item {
  margin-bottom: 8px;
}
.src-components-Tickets-WechatAccount-WechatAccount-module__container--3elY6 .ant-form-item label {
  font-size: 13px;
  color: var(--aic-secondary);
  font-weight: 500;
}
.src-components-Tickets-WechatAccount-WechatAccount-module__container--3elY6 .ant-form-item .ant-form-item-label {
  padding: 0 !important;
}
.src-components-Tickets-WechatAccount-WechatAccount-module__container--3elY6 .ant-form-explain {
  font-size: 12px;
}
.src-components-Tickets-WechatAccount-WechatAccount-module__details--2fZPW {
  width: 100%;
  padding: 20px;
  height: calc(100vh - 120px);
  overflow-y: scroll;
}
.src-components-Tickets-WechatAccount-WechatAccount-module__buttons--Hr-am {
  display: flex;
  justify-content: flex-end;
}
.src-components-Tickets-WechatAccount-WechatAccount-module__text--2REqZ {
  display: flex;
  align-items: center;
  justify-content: center;
  height: calc(100vh - 220px);
}

.src-components-Tickets-WechatAccount-WechatAccountDetails-WechatAccountDetails-module__container--1qwCk .ant-form-item {
  margin-bottom: 8px;
}
.src-components-Tickets-WechatAccount-WechatAccountDetails-WechatAccountDetails-module__container--1qwCk .ant-form-item label {
  font-size: 13px;
  color: var(--aic-secondary);
  font-weight: 500;
}
.src-components-Tickets-WechatAccount-WechatAccountDetails-WechatAccountDetails-module__container--1qwCk .ant-form-item .ant-form-item-label {
  padding: 0 !important;
}
.src-components-Tickets-WechatAccount-WechatAccountDetails-WechatAccountDetails-module__container--1qwCk .ant-form-explain {
  font-size: 12px;
}
.src-components-Tickets-WechatAccount-WechatAccountDetails-WechatAccountDetails-module__title--4VlRx {
  color: var(--aic-secondary);
  margin: 24px 0px 10px 0px !important;
  font-size: 16px;
  text-align: left;
}

.src-components-Tickets-WechatAccount-IssueTypes-IssueTypes-module__container--12J64 .ant-table-body {
  background: white;
}
.src-components-Tickets-WechatAccount-IssueTypes-IssueTypes-module__add--2FV7E {
  padding: 10px 0px;
  display: flex;
  justify-content: flex-end;
}



.src-components-Tickets-TicketsHome-module__container--1LmbZ {
  background: white;
}
.src-components-Tickets-TicketsHome-module__container--1LmbZ .ant-tabs-bar {
  margin: 0 !important;
}

.src-components-UserFeedbacks-UserFeedback-module__container--3UCbm {
  padding: 24px;
}
.src-components-UserFeedbacks-UserFeedback-module__container--3UCbm h2 {
  color: var(--aic-green);
  padding: 0px 0px 10px;
}
.src-components-UserFeedbacks-UserFeedback-module__container--3UCbm table {
  background: white;
}

.src-components-Bulletins-Bulletin-module__container--1MdqK {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  position: relative;
}
.src-components-Bulletins-Bulletin-module__container--1MdqK .ant-menu-inline {
  border: none;
}
.src-components-Bulletins-Bulletin-module__container--1MdqK li.ant-menu-item {
  height: 65px;
}
.src-components-Bulletins-Bulletin-module__container--1MdqK .ant-tag {
  width: 80px;
}
.src-components-Bulletins-Bulletin-module__menu--3cyZq {
  background: white;
  width: 280px;
  height: 100%;
  position: relative;
  border-right: 1px solid #e8e8e8;
}
.src-components-Bulletins-Bulletin-module__menu--3cyZq li {
  text-align: left;
}
.src-components-Bulletins-Bulletin-module__buttons--3e4lf {
  display: flex;
  justify-content: space-between;
  padding: 4px;
  position: absolute;
  left: 0px;
  bottom: 0px;
  width: 100%;
}
.src-components-Bulletins-Bulletin-module__buttons--3e4lf button {
  margin: 4px;
}
.src-components-Bulletins-Bulletin-module__content--kRU3C {
  width: calc(100% - 280px);
  overflow-y: auto;
  height: 100%;
  background: white;
}
.src-components-Bulletins-Bulletin-module__flex--1M0a- {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}
.src-components-Bulletins-Bulletin-module__List--SURPz {
  display: flex;
  flex-direction: column-reverse;
}
.src-components-Bulletins-Bulletin-module__List--SURPz p {
  overflow: auto;
}
@media (max-width: 700px) {
  .src-components-Bulletins-Bulletin-module__content--kRU3C {
    width: 100%;
  }
  .src-components-Bulletins-Bulletin-module__menu--3cyZq {
    width: 80px;
    left: 0px;
    border-right: 0em;
  }
}

.src-components-Bulletins-Editor-Editor-module__container--2YArm {
  height: 100%;
  position: relative;
}
.src-components-Bulletins-Editor-Editor-module__container--2YArm .rdw-editor-wrapper {
  height: calc(100% - 52px);
}
.src-components-Bulletins-Editor-Editor-module__container--2YArm .rdw-editor-main {
  height: calc(100% - 76px);
  max-height: unset !important;
}
.src-components-Bulletins-Editor-Editor-module__container--2YArm .rdw-image-imagewrapper {
  width: 100% !important;
  position: relative;
}
.src-components-Bulletins-Editor-Editor-module__container--2YArm .rdw-image-imagewrapper img {
  max-width: 100% !important;
  position: relative;
  display: block;
  margin: auto;
}
.src-components-Bulletins-Editor-Editor-module__actions--3_yXb {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding: 5px;
}
.src-components-Bulletins-Editor-Editor-module__actions--3_yXb > * {
  display: inline-block;
  margin: 5px !important;
}
.src-components-Bulletins-Editor-Editor-module__intro--35NY- {
  display: flex;
  width: 100%;
  height: 100%;
  align-items: center;
  justify-content: center;
}

.src-components-Badge-Badge-module__container--XldgG {
  padding: 24px;
}
.src-components-Badge-Badge-module__summaryTable--185rl {
  display: block;
  width: 100%;
  margin-bottom: 24px;
}
.src-components-Badge-Badge-module__summaryTable--185rl tbody {
  display: flex;
  justify-content: space-around;
  align-items: flex-start;
  padding: 20px;
  background: aliceblue;
  width: 100%;
  border-radius: 4px;
}
.src-components-Badge-Badge-module__summaryTable--185rl tbody tr {
  display: flex;
  flex-direction: column;
}
.src-components-Badge-Badge-module__summaryTable--185rl tbody tr td:first-child {
  font-size: 12px;
}
.src-components-Badge-Badge-module__summaryTable--185rl tbody tr td:not(:first-child) {
  font-size: 28px;
  text-align: center;
}
.src-components-Badge-Badge-module__summaryTable--185rl tbody tr td:not(:first-child) span {
  font-size: 14px;
}
@media (max-width: 470px) {
  .src-components-Badge-Badge-module__summaryTable--185rl tbody tr td:first-child {
    font-size: 10px;
  }
  .src-components-Badge-Badge-module__summaryTable--185rl tbody tr td:not(:first-child) {
    font-size: 18px;
    text-align: center;
  }
  .src-components-Badge-Badge-module__summaryTable--185rl tbody tr td:not(:first-child) span {
    font-size: 10px;
  }
}

.src-components-Companies-Companies-module__container--2-o0B {
  padding: 20px 40px;
  width: 100%;
}
.src-components-Companies-Companies-module__container--2-o0B .ant-table {
  margin-top: 40px;
}
.src-components-Companies-Companies-module__container--2-o0B .ant-avatar > img {
  border: 1px solid #e6e6e6;
  border-radius: 20%;
}
.src-components-Companies-Companies-module__container--2-o0B .ant-avatar.ant-avatar-square.ant-avatar-icon {
  border: 1px solid #e6e6e6;
  border-radius: 20%;
}
.src-components-Companies-Companies-module__avatar--wql_l > a {
  display: flex;
  align-items: center;
}
.src-components-Companies-Companies-module__logo--3sE4r {
  padding-right: 8px;
}
@media (max-width: 450px) {
  .src-components-Companies-Companies-module__container--2-o0B {
    padding: 20px 10px;
  }
}

.src-components-UI-GridApps-GridApps-module__more--2GCVH {
  width: 45px;
  height: 45px;
  margin-top: 0px !important;
  border: 1px solid #e6e6e6;
  border-radius: 20%;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0px 8px !important;
}
.src-components-UI-GridApps-GridApps-module__more--2GCVH > p {
  color: #97c549;
  font-weight: bold;
}
.src-components-UI-GridApps-GridApps-module__flex--2CiN3 {
  display: inline-flex;
  justify-content: center;
  align-items: flex-start;
}

.src-components-CompanyDetails-CompanyDetails-module__container--1vZcC {
  padding: 20px 40px;
  width: 100%;
}
.src-components-CompanyDetails-CompanyDetails-module__container--1vZcC .ant-card {
  margin-top: 20px;
}
.src-components-CompanyDetails-CompanyDetails-module__container--1vZcC .ant-table-fixed {
  max-width: 200px;
}
@media (max-width: 555px) {
  .src-components-CompanyDetails-CompanyDetails-module__container--1vZcC {
    padding: 20px 10px;
  }
  .src-components-CompanyDetails-CompanyDetails-module__container--1vZcC .ant-col.ant-col-4.ant-form-item-label.ant-form-item-label-left {
    display: contents;
  }
  .src-components-CompanyDetails-CompanyDetails-module__container--1vZcC .ant-row.ant-form-item {
    display: flex;
    flex-direction: column;
  }
  .src-components-CompanyDetails-CompanyDetails-module__container--1vZcC .ant-col.ant-col-10.ant-form-item-control-wrapper {
    width: 100% !important;
  }
}
@media (max-width: 450px) {
  .src-components-CompanyDetails-CompanyDetails-module__container--1vZcC .ant-table-fixed {
    max-width: 70px;
  }
}

.src-components-CompanyDetails-CardDetailsForm-CardDetailsForm-module__logo--U-ffX {
  display: flex;
  align-items: center;
}

.src-components-Projects-Projects-module__container--rxwiX {
  padding: 20px 40px;
  width: 100%;
}
.src-components-Projects-Projects-module__container--rxwiX .src-components-Projects-Projects-module__table--2iIFg {
  overflow-x: scroll;
}
.src-components-Projects-Projects-module__container--rxwiX .ant-table {
  margin-top: 40px;
  min-width: 700px;
}
.src-components-Projects-Projects-module__container--rxwiX .ant-avatar > img {
  border: 1px solid #e6e6e6;
  border-radius: 20%;
}
.src-components-Projects-Projects-module__container--rxwiX .ant-avatar.ant-avatar-square.ant-avatar-icon {
  border: 1px solid #e6e6e6;
  border-radius: 20%;
}
.src-components-Projects-Projects-module__container--rxwiX .ant-table-layout-fixed table {
  table-layout: auto;
}
.src-components-Projects-Projects-module__logo--9qgQ3 {
  padding-right: 8px;
}
@media (max-width: 1024px) {
  .ant-table {
    background: purple !important;
  }
}
@media (max-width: 450px) {
  .src-components-Projects-Projects-module__container--rxwiX {
    padding: 20px 10px;
  }
}

.src-components-ProjectsDetails-ProjectDetails-module__container--5aUCe {
  padding: 20px 40px;
  width: 100%;
}
.src-components-ProjectsDetails-ProjectDetails-module__container--5aUCe .ant-card {
  margin-top: 20px;
}
.src-components-ProjectsDetails-ProjectDetails-module__logo--3K2HI {
  display: flex;
  align-items: center;
}
.src-components-ProjectsDetails-ProjectDetails-module__logo--3K2HI > img {
  margin-right: 8px;
}
@media (max-width: 450px) {
  .src-components-ProjectsDetails-ProjectDetails-module__container--5aUCe .ant-table-fixed {
    max-width: 70px;
  }
}
@media (max-width: 555px) {
  .src-components-ProjectsDetails-ProjectDetails-module__container--5aUCe {
    padding: 20px 10px;
  }
  .src-components-ProjectsDetails-ProjectDetails-module__container--5aUCe .ant-row.ant-form-item {
    display: flex;
    justify-content: space-between;
  }
  .src-components-ProjectsDetails-ProjectDetails-module__container--5aUCe .ant-col.ant-col-4.ant-form-item-label.ant-form-item-label-left {
    display: contents;
  }
  .src-components-ProjectsDetails-ProjectDetails-module__container--5aUCe .ant-row.ant-form-item {
    display: flex;
    flex-direction: column;
  }
  .src-components-ProjectsDetails-ProjectDetails-module__container--5aUCe .ant-col.ant-col-10.ant-form-item-control-wrapper {
    width: 100% !important;
  }
  .src-components-ProjectsDetails-ProjectDetails-module__container--5aUCe .ant-card-head-wrapper {
    display: flex;
    flex-direction: column;
  }
  .src-components-ProjectsDetails-ProjectDetails-module__container--5aUCe .ant-card-extra {
    margin: 0px;
  }
  .src-components-ProjectsDetails-ProjectDetails-module__container--5aUCe .src-components-ProjectsDetails-CardDetailsForm-CardDetailsForm-module__actions--1X2A0 {
    display: grid;
    grid-gap: 10px;
  }
}

.src-components-ProjectsDetails-CardDetailsForm-CardDetailsForm-module__logo--2ySdk {
  display: flex;
  align-items: center;
}
.src-components-ProjectsDetails-CardDetailsForm-CardDetailsForm-module__actions--1X2A0 {
  display: flex;
}
.src-components-ProjectsDetails-CardDetailsForm-CardDetailsForm-module__actions--1X2A0 .ant-btn {
  margin-left: 8px;
}

.src-components-ClientDetails-ClientDetails-module__container--3PKCJ {
  grid-row-gap: 20px;
  padding: 20px;
}
.src-components-ClientDetails-ClientDetails-module__container--3PKCJ .ant-form-item-label {
  text-align: left;
}
.src-components-ClientDetails-ClientDetails-module__container--3PKCJ .ant-col {
  padding: 0 !important;
}
.src-components-ClientDetails-ClientDetails-module__container--3PKCJ .ant-btn {
  margin-left: 8px;
}
.src-components-ClientDetails-ClientDetails-module__blocked--3Y7qk {
  display: flex !important;
}
.src-components-ClientDetails-ClientDetails-module__goBack--1NEXy {
  margin: 0px 10px 10px 0px !important;
  background: white !important;
}
.src-components-ClientDetails-ClientDetails-module__loader--3mutR {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 350px;
  padding: 20px;
}
.src-components-ClientDetails-ClientDetails-module__box--2QRY9 {
  display: grid;
  grid-template-columns: 1fr;
  grid-template-rows: 1fr;
  grid-gap: 10px;
}
.src-components-ClientDetails-ClientDetails-module__bottomSection--3bked {
  display: flex;
  justify-content: flex-end;
}
@media (max-width: 555px) {
  .src-components-ClientDetails-ClientDetails-module__container--3PKCJ .ant-card-head-wrapper {
    display: flex;
    flex-direction: column;
  }
  .src-components-ClientDetails-ClientDetails-module__container--3PKCJ .ant-card-extra {
    margin: 0px;
  }
  .src-components-ClientDetails-ClientDetails-module__container--3PKCJ .ant-row-flex.ant-row-flex-space-between.src-components-ClientDetails-ClientDetails-module__Buttons--2vdlY {
    display: grid;
    grid-gap: 10px;
  }
  .src-components-ClientDetails-ClientDetails-module__Buttons--2vdlY {
    flex-direction: column;
  }
}
@media (max-width: 770px) {
  .src-components-ClientDetails-ClientDetails-module__container--3PKCJ {
    justify-content: center;
    grid-row-gap: 20px;
    padding: 20px 20px 60px;
  }
}

.src-components-OperationsKPI-OperationsKPI-module__container--3lcu9 {
  padding: 20px;
}
.src-components-OperationsKPI-OperationsKPI-module__container--3lcu9 h3 {
  text-align: left;
}
.src-components-OperationsKPI-OperationsKPI-module__container--3lcu9 .ant-table {
  margin-top: 20px;
}
.src-components-OperationsKPI-OperationsKPI-module__container--3lcu9 .ant-card {
  margin-top: 20px;
  width: 100%;
}
.src-components-OperationsKPI-OperationsKPI-module__datePicker--zyrfG {
  margin-right: 20px;
}
.src-components-OperationsKPI-OperationsKPI-module__headers--3Tosq p {
  margin-right: 10px;
}

.src-components-OperationsKPI-AppsDistributionTime-AppDistributionTime-module__container--3m5w6 {
  padding: 20px;
}
.src-components-OperationsKPI-AppsDistributionTime-AppDistributionTime-module__title--ORUOg {
  margin: 10px 0px;
}
.src-components-OperationsKPI-AppsDistributionTime-AppDistributionTime-module__datePickerContainer--1SwRG {
  margin: 0px 0px 20px;
}
.src-components-OperationsKPI-AppsDistributionTime-AppDistributionTime-module__datePicker--2KoTg {
  margin-right: 20px;
}
.src-components-OperationsKPI-AppsDistributionTime-AppDistributionTime-module__headers--3nzDQ p {
  margin-right: 10px;
}

.src-components-OperationsKPI-VersionsDistributionTime-VersionsDistributionTime-module__container--1QPjH {
  padding: 20px;
}
.src-components-OperationsKPI-VersionsDistributionTime-VersionsDistributionTime-module__container--1QPjH .ant-table-title {
  text-align: left;
}
.src-components-OperationsKPI-VersionsDistributionTime-VersionsDistributionTime-module__title--ADJfZ {
  margin: 10px 0px;
}
.src-components-OperationsKPI-VersionsDistributionTime-VersionsDistributionTime-module__title--ADJfZ p {
  font-weight: 500;
  font-size: 20px;
  margin: 10px 5px 0px;
}
.src-components-OperationsKPI-VersionsDistributionTime-VersionsDistributionTime-module__datePickerContainer--2B9z7 {
  margin: 0px 0px 20px;
}
.src-components-OperationsKPI-VersionsDistributionTime-VersionsDistributionTime-module__datePicker--PpYkB {
  margin-right: 16px;
}
.src-components-OperationsKPI-VersionsDistributionTime-VersionsDistributionTime-module__versionTitle--3Itlc {
  text-align: left;
  font-weight: 600;
  font-size: 20px;
}
.src-components-OperationsKPI-VersionsDistributionTime-VersionsDistributionTime-module__headers--J2ffx p {
  margin-right: 10px;
}

:root{
  --aic-green: #8dbe40;
  --aic-second-green: #5c7d2b;
  --light-gray: rgba(255,255,255,0.87);
  --gray: gray;
  --green: #31c537;
  --yellow: #f3b454;
}
* {box-sizing: border-box;}
body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background: whitesmoke;
  font-family: roboto;
  font-weight: 300;
  margin: 0;
  padding: 0;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
body * {
  font-family: "Roboto", sans-serif;
}
p,
h1,
h2,
h3,
h4,
h5 {
  margin: 0;
  padding: 0;
}

::-webkit-scrollbar {
  display: none;
}

:root {
  --aic-primary: #8dbe40;
  --error-color: #ff868a;
  --aic-secondary: #5c7d2b;
}
.ant-btn {
  font-weight: 700;
}
::selection {
  background: #cccccc;
}
.ant-btn.ant-btn-circle .anticon {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-btn-background-ghost.ant-btn-primary.ant-btn-circle {
  text-shadow: none;
  display: inline-flex;
  justify-content: center;
  align-items: center;
}
.rdw-link-modal {
  min-height: 225px !important;
}
.ant-table-content {
  background: white;
  border-left: 1px solid gainsboro !important;
  border-right: 1px solid gainsboro !important;
  border-bottom: 1px solid gainsboro !important;
}
.ant-card-head-wrapper {
  text-align: left;
}
.ant-card-head {
  text-align: left;
  background: #fbfbfb;
}
.ant-tabs-nav-container {
  background: white;
  border-bottom: 1px solid gainsboro;
}
.ant-card-body p {
  margin: 0px 0px 20px;
}
.ant-card-body table {
  border-collapse: collapse;
  width: 100%;
}
.ant-card-body td {
  text-align: center;
  padding: 7px 0px;
}
.ant-card-body tr:not(:last-child) {
  border-bottom: 1px solid gainsboro;
}
.ant-card-body td:first-child {
  text-align: left;
}
.ant-card-body td:last-child {
  text-align: right;
  font-weight: 500;
}
.ant-table-content {
  border: none;
}
.ant-table-title {
  position: relative;
  top: 1px;
  padding: 16px 0;
  border-radius: 4px 4px 0 0;
  border: 1px solid gainsboro;
  font-weight: 700;
  text-align: center;
  padding-left: 16px;
  background: #f3f3f3;
}
.public-DraftStyleDefault-block {
  margin: 0 !important;
}
.demo-editor {
  padding: 0px 20px;
}

