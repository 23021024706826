.Button{
	background: #8dbf40;
    color: white;
    font-size: 18px;
    height: 50px;
    width: 100px;
    border: none;
    box-shadow: 0px 3px 20px 0px #67676733;
    transition: all 0.2s;
    font-weight: 700;
    outline: none;
    cursor: pointer;
}
.Button:not(.Loading):hover{
	background: #7da939;
}
.White{
    background: white;
    color: #8dbf40;
    border:1px solid #efefef;
}
.White:hover{
    background: whitesmoke !important;
}
.Ghost{
    background: #ffffff00;
    border: 2px solid #8dbf40 !important;
    border-radius: 3px;
    color: #8dbf40;
    box-shadow: none;
}
.Ghost:hover{
    background: #8dbf40 !important;
    color: white;
}
.Round{
    width: 50px;
    border-radius: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.Round * {
    filter: brightness(10);
}
.Payment{
    width: 245px;
    height: 35px;
    border-radius: 4px;
    box-shadow: none;
    background: gray;
    font-size: 14px;
    transition: all 0s;
}
.Payment:hover{
    background: #9f9f9f !important;
}
.Delete{
    background: #ef5659;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50px;
    width: 50px;
}
.Delete:hover{
    background: #d23f42 !important;
}
.Delete > *{
    filter: brightness(10);
}
.Plain{
    border: none !important;
    height: auto !important;
    width: auto !important;
    color: gray;
    background: none !important;
    box-shadow: none !important;
    margin: 10px auto;
    text-decoration: underline;
    font-weight: 500;
}

@media (max-width: 770px){
    .Button{
        height: 38px;
        margin: 0 3px;
    }
    .Round{
        width: 38px;
    }
    .Delete {
        width: 38px;
    }
}