.Container {
  display: flex;
  /* align-items: flex-end; */
  justify-content: space-between;
}
.SearchContainer {
  margin-bottom: 20px;
  width: calc(100% - 200px);
}
.filtercontainer {
  display: grid;
  grid-template-columns: 1fr 1fr auto;
  grid-gap: 10px;
  margin-top: 20px;
}
.filterButtons {
  margin-left: 20px;
  width: 200px;
}

.advancedsearch {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  grid-gap: 10px;
  margin-top: 20px;
}
.advancedsearch b {
  font-size: 11px !important;
  text-align: left;
  color: var(--aic-secondary);
  font-weight: 500;
  margin: 0;
}

.filtercontainer > span > b {
  margin-bottom: 3px;
  display: block;
}

.advanced {
  cursor: pointer;
  margin: 5px 0px 10px !important;
}

@media (max-width: 650px) {
  .filtercontainer {
    grid-template-columns: 1fr;
  }
}

@media screen and (max-width: 500px) {
  .filtercontainer > span > b {
    font-size: small;
  }
}
@media screen and (max-width: 460px) {
  .SearchContainer {
    width: 100% !important;
  }
  .Container {
    flex-direction: column;
  }
}
