.Container{
	width: 100%;
	border-radius: 6px;
	background: #f6f6f6;
	padding: 8px;
	display: flex;	
	justify-content: space-between;
	align-items: center;
	height: 40px;
}

.Container > div{
	display: flex;
	align-items: center;
	width: 62px;
	justify-content: space-between;
}

.Container a{
	display: flex;
    align-items: center;
}

.Container svg{
	opacity: 0.9;
}

.label{
	margin: 0 !important;
	font-size: 16px;
}

.modified, .modified label{
	background:#fddcc5;
}
